import { TextField } from '@fluentui/react';
import { DatePicker } from 'office-ui-fabric-react';
import React, { useState } from 'react';
import Button from '../../components/Common/Button/Button';
import "./index.css"
import { useDispatch } from 'react-redux';
import { registerUser } from '../../redux/actions/API/login';
import { useParams } from 'react-router-dom';
import { timeFormat } from '../../utils/CommonFunction';
import logo from "../../assets/webodhi.png";
import axios from "axios"
import Swal from 'sweetalert2';

interface AddStudentProps {

}

const AddStudent: React.FC<AddStudentProps> = () => {

    const [firstName, setFirstName] = useState<string>('')
    const [middleName, setMiddleName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [contactNumber, setContactNumber] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [dateOfBirth, setDateOfBirth] = useState<any>('')
    const [loading , setLoading] = useState<boolean>(false)

    const dispatch = useDispatch()
    const params = useParams()
    const paramsLen: any = params.id

    const handleChange = (e: any, name: string) => {
        if (name === "firstName") {
            setFirstName(e.target.value)
        } else if (name === "middleName") {
            setMiddleName(e.target.value)
        } else if (name === "lastName") {
            setLastName(e.target.value)
        } else if (name === "contactNumber") {
            const inputValue = e.target.value;
            const numericValue = inputValue.replace(/[^0-9]/g, '');
            if (numericValue.length <= 10) {
                setContactNumber(numericValue)
            }
        } else if (name === "email") {
            setEmail(e.target.value)
        } else if (name === "dateOfBirth") {
            setDateOfBirth(e)
        }
    }


    const registerClick = async (e: any) => {
        e.preventDefault()
        let decData: any
        let token: any
        if (params.token) {
            token = atob(params.token)
        }
        if (paramsLen) {
            const data2: any = params.id
            decData = atob(data2)
        }
        const data = {
            classId: decData,
            dateOfBirth: timeFormat(dateOfBirth),
            emailId: email,
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            personalAddress: "NA",
            phoneNumber: contactNumber,
            roleId: paramsLen ? 3 : 2,
            userAccountStatus: 1,
        }

        try {
            setLoading(true)
            const response = await axios.post(`${process.env.REACT_APP_API_URL}Login/Register`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: token,
                    },
                });
    
            if (response.status === 200) {
                Swal.fire({
                    html: `<div class='show_user_pass'>
                                <p>${paramsLen ? 'Student' : 'Teacher'} has been added successfully</p>
                                <ul class='username_pass'>
                                    <li>
                                        <span>Username </span>- ${response.data?.username}
                                    </li>
                                    <li>
                                        <span>Password </span>- ${response.data?.password}
                                    </li>
                                </ul>
                            </div>`,
                    text: "",
                    icon: "success"
                });
            }
            setFirstName("")
            setMiddleName("")
            setLastName("")
            setContactNumber("")
            setEmail("")
            setDateOfBirth("")
        } catch (error) {
            
        }finally{
            setLoading(false)
        }
    }



    return (
        <div style={{ padding: "20px" }} className='addStudentContainer'>
            <img className='logo_img' src={logo} alt="logo"></img>
            <h2>Register {paramsLen ? "Student" : "Teacher"} for Instution Name</h2>
            <div className="addDepartmentForm">
                <TextField
                    label="First Name"
                    placeholder="First Name"
                    className="loginField"
                    name='firstName'
                    required
                    value={firstName}
                    onChange={(e: any) => handleChange(e, "firstName")}
                />
                <TextField
                    label="Middle Name"
                    placeholder="Middle Name"
                    className="loginField"
                    name='middleName'
                    value={middleName}
                    onChange={(e: any) => handleChange(e, "middleName")}
                />
                <TextField
                    label="Last Name"
                    placeholder="Last Name"
                    className="loginField"
                    name='lastName'
                    required
                    value={lastName}
                    onChange={(e: any) => handleChange(e, "lastName")}
                />
                <TextField
                    label="Contact number"
                    placeholder="Contact number"
                    className="loginField"
                    name='contactNumber'
                    required
                    value={contactNumber}
                    onChange={(e: any) => handleChange(e, "contactNumber")}
                />
                <TextField
                    label="Email"
                    placeholder="Email"
                    className="loginField"
                    name='email'
                    required
                    value={email}
                    onChange={(e: any) => handleChange(e, "email")}
                />
                <DatePicker
                    isRequired
                    label="Date of birth"
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={dateOfBirth}
                    onSelectDate={(e: any) => handleChange(e, "dateOfBirth")}
                />
                <div className="modal_footer_btn">
                    <Button loading={loading}  className='modal_ok_btn' onClick={registerClick} btnText={`Register ${paramsLen ? "Student" : "Teacher"}`} />
                </div>
            </div>
        </div>
    );
};

export default AddStudent;