import { combineReducers } from 'redux'
import {  ChangePasswordReducer, EnableAnalytic, GetCourseDetails,  MoveFolder, MovePage, addCoursesReducer, addHeirarchyReducer, addPageReducer, addQuestionPageReducer, addRegularPageReducer, addTopic, convertPage, deleteCoursesReducer, deleteHeirarchyReducer, deletePageReducer, editCoursesReducer, editHeirarchyReducer, getAiPages , getAnalyticReducer, getCoursePagesNewReducer, getCoursePagesReducer, getCoursesReducer, getHelpReducer, getPagesReducer, getQuestionBank, getStudentCoursesReducer, getSubmission, getTopics, getUserProfile, submitQuizReducer, updateQuestionPageReducer, updateRegularPageReducer, updateUserprofile, viewSubmissionReducer } from './getCourses';
import { addClassReducer, addDepartmentReducer, getDepartmentClassesReducer, editDepartmentReducer, deleteDepartmentReducer, editClassReducer, deleteClass } from './getDepartmentClasses';
import login, { deleteUserReducer, forgotPassword, refreshTokenReducer } from './login'
import spinnerReducer, { actionLoaderReducer, loaderReducer, offlinePageData } from './spinnerReducer';
import { getStudentsByClassReducer, getTeacherCoursesReducer } from './teacherCourses';
import userMetaDataReducer, { setAllCoursesDataReducer, setUnsavedChangesDataReducer } from './userMetadataReducer';
import { appAppBreadCrumb, appLocationReducer, appPreviousLocationReducer, setScreenProps } from './appLocationReducer';
import previousLocationData from './previousLocationData';
import { getInstitutions } from './getInstitutions';

const UIReducers = combineReducers({    
    userMetadata: userMetaDataReducer,
    offlinePageData: offlinePageData,
    unsavedChangsData: setUnsavedChangesDataReducer,
    allCoursesData: setAllCoursesDataReducer,
    appLocation: appLocationReducer,
    spinnerStatus: spinnerReducer,
    loaderStatus: loaderReducer,
    actionLoader: actionLoaderReducer,
    appPreviousLocation: appPreviousLocationReducer,
    appBreadcrumb: appAppBreadCrumb,
    screenProps : setScreenProps,
    previousLocationData : previousLocationData
});
const APIReducers = combineReducers({
    login: login,
    forgotPassword: forgotPassword,
    refreshToken: refreshTokenReducer,
    getDepartmentClasses: getDepartmentClassesReducer,
    addDepartment: addDepartmentReducer,
    editDepartment: editDepartmentReducer,
    deleteDepartment: deleteDepartmentReducer,
    addClass: addClassReducer,
    editClass: editClassReducer,
    getTeacherCourses: getTeacherCoursesReducer,
    getCourses: getCoursesReducer,
    getStudentCourses: getStudentCoursesReducer,
    getCoursePages: getCoursePagesReducer,
    getCoursePagesNew: getCoursePagesNewReducer,
    getAiPages: getAiPages,
    getTopics: getTopics,
    getQuestionBank: getQuestionBank,
    addTopic: addTopic,
    getPages: getPagesReducer,
    addCourses: addCoursesReducer,
    editCourses: editCoursesReducer,
    deleteCourses: deleteCoursesReducer,
    addHeirarchy: addHeirarchyReducer,
    editHeirarchy: editHeirarchyReducer,
    addRegularPage: addRegularPageReducer,
    addPage: addPageReducer,
    addQuestionPage: addQuestionPageReducer,
    updateRegularPage: updateRegularPageReducer,
    convertPage: convertPage,
    updateQuestionPage: updateQuestionPageReducer,
    deletePage: deletePageReducer,
    deleteHeirarchy: deleteHeirarchyReducer,
    getStudentsByClass: getStudentsByClassReducer,
    deleteUser: deleteUserReducer,
    viewSubmission: viewSubmissionReducer,
    getSubmission: getSubmission,
    submitQuiz: submitQuizReducer,
    getAnalytic: getAnalyticReducer,
    deleteClass : deleteClass,
    getUserProfile : getUserProfile,
    updateUserprofile : updateUserprofile,
    GetCourseDetails : GetCourseDetails,
    EnableAnalytic : EnableAnalytic,
    MovePage : MovePage,
    MoveFolder : MoveFolder,
    ChangePassword : ChangePasswordReducer,
    getHelp : getHelpReducer,
    getInstitutions: getInstitutions,

});

const RootReducer = combineReducers({
    api: APIReducers,
    ui: UIReducers
});

export default RootReducer;