import React from 'react';

interface FailedScreenProps {
    // Define your props here
}

const FailedScreen: React.FC<FailedScreenProps> = (props) => {
    return (
        <>
            <img src="https://firebasestorage.googleapis.com/v0/b/test-project-7bfa2.appspot.com/o/remove.png?alt=media&token=81452cf7-0322-41ac-bec5-65977274143b" alt="" />
            <div className='success_text_section'>
                <h1>Payment Failed</h1>
                <p>Your payment was not completed. Please try again.</p>
            </div>
        </>
    );
};

export default FailedScreen;