import { Callout, mergeStyleSets } from '@fluentui/react';
import { Tooltip } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import admin from "../../../assets/admin.png";
import student from "../../../assets/student.png";
import teacher from "../../../assets/teacher.png";
import "./index.css"
import {
    Persona,
    PersonaSize,
    PersonaPresence,
} from "@fluentui/react/lib/Persona";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { setUserMetadata } from '../../../redux/actions/UI/userMetadata';
import { useNavigate } from 'react-router-dom';
import PatternIcon from '@mui/icons-material/Pattern';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { axiosInstance, fetchGet } from '../../../API/Baseurl';
import BackupIcon from '@mui/icons-material/Backup';
import DownloadIcon from '@mui/icons-material/Download';
import HelpIcon from '@mui/icons-material/Help';
import { openNotification } from '../../../utils/CommonFunction';

const styles = mergeStyleSets({
    button: {
        width: 130,
    },
    callout: {
        width: 150,
        padding: '20px 20px',
    },
});
interface LogoutSectionProps {
    // Define your props here
    callOut: boolean;
    helpOpen: boolean,
    setHelpOpen: any,
    calloutClick: () => void;
    profileClick: () => void;
    changePasswordClick: () => void;
}
const LogoutSection: React.FC<LogoutSectionProps> = ({ callOut, calloutClick, profileClick, changePasswordClick, helpOpen, setHelpOpen }) => {
    const userMetadata = useSelector((state: any) => state.ui.userMetadata)
    const getUserProfileData = useSelector((state: any) => state.api?.getUserProfile?.data)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    if (!callOut) {
        return null; // or any other placeholder element
    }

    const clickBackup = async (e: any) => {
        try {
          await fetchGet("SuperAdmin/backup");
          openNotification("topRight", "Backup successfully created", "success");
        } catch (error) {
          openNotification("topRight", "something went wrong", "error");
    
        }
    
    
        e.preventDefault()
      }
    
      const downloadDatabase = async () => {
        const token = localStorage.getItem("jwt")
        try {
          const response = await axiosInstance({
            url: 'SuperAdmin/download',
            method: 'GET',
            responseType: 'blob',
            headers: {
              Authorization: token ? token : "",
            }
          });
    
          // Create a blob from the response data
          const blob = new Blob([response.data], { type: 'application/octet-stream' });
    
          // Create a link element
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'database.db'; // Set the file name
    
          // Append the link to the body (it's invisible)
          document.body.appendChild(link);
    
          // Programmatically click the link to trigger the download
          link.click();
    
          // Clean up and remove the link
          link?.parentNode?.removeChild(link);
        } catch (error) {
          console.error('Failed to download the database backup:', error);
        }
      };
    const clickHelp = () => {
        setHelpOpen(true)
    }
    return (

        callOut &&
        <Callout onDismiss={calloutClick} className={`callout_head ${styles.callout}`} role="alert">

            <div className="logOut">

                <Tooltip
                    className='admin_name'
                    title={
                        userMetadata.role !== "Student"
                            ? "Admin"
                            : userMetadata.role === "Student"
                                ? "Student"
                                : "Teacher"
                    }
                >
                    <img
                        src={
                            userMetadata.role !== "Student"
                                ? admin
                                : userMetadata.role === "Student"
                                    ? student
                                    : teacher
                        }
                        alt="role"
                        style={{ height: "20px" }}
                        className="persona"
                    ></img>
                    <p>

                        {
                            userMetadata.role !== "Student"
                                ? "Admin"
                                : userMetadata.role === "Student"
                                    ? "Student"
                                    : "Teacher"
                        }
                    </p>
                </Tooltip>
            </div>
            <div className="logOut">
                <Persona
                    {...{
                        imageUrl: "",
                        imageInitials: "",
                        text: getUserProfileData?.firstName,
                        tertiaryText: "In a meeting",
                        optionalText: "Available at 4:00pm",
                        showSecondaryText: true,
                    }}
                    size={PersonaSize.size24}
                    presence={PersonaPresence.none}
                    imageAlt={getUserProfileData?.firstName}
                />
            </div>
            <div className="logOut" onClick={clickHelp}>
                <HelpIcon />
                <span>Help Section</span>
            </div>
            <div className="logOut" onClick={profileClick}>
                <AccountBoxIcon />
                <span>View Profile</span>
            </div>
            <div className="logOut" onClick={changePasswordClick}>
                <PatternIcon />
                <span>Change Password</span>

            </div>
            {userMetadata.role === "SuperAdmin" ?
                <>
                    <div className="logOut" onClick={clickBackup}>
                        <BackupIcon />
                        <span>Back UP</span>

                    </div>
                    <div className="logOut" onClick={downloadDatabase}>
                        <DownloadIcon />
                        <span>Download</span>

                    </div>
                </> : ""
            }

            <div className="logOut" onClick={() => {
                navigate("/")
                const data = {
                    username: "",
                    role: "",
                    token: "",
                    isLoggedIn: false,
                    InstitutionName: "",
                    institutionId: "",
                    password: "",
                    id: "",
                }
                localStorage.removeItem("token")
                dispatch(setUserMetadata(data))
            }}>
                <PowerSettingsNewIcon />
                <span>Logout</span>
            </div>
        </Callout>)

};

export default LogoutSection;