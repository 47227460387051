import React, { Suspense } from 'react';
import Header from '../../components/Common/Header';
import { Outlet } from 'react-router-dom';
import Sidebarnav from '../../components/Common/sidebarNav';
import "./index.css"
import SuspenseLoader from '../../components/suspenseLoader';

interface DashboardLayoutProps {
    // Define your props here
}

const DashboardLayout: React.FC<DashboardLayoutProps> = () => {

    return (
        <div className="dashboardBody">
            <Header />
            <div className="sidenavDashContainer">
                <Sidebarnav />
                <div className="dashboardElementContainer">
                    <Suspense fallback={<SuspenseLoader />}>
                        <Outlet />
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;