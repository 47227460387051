import{ Suspense } from 'react'
import { Outlet } from 'react-router-dom'

const LoginLayout = () => {
    return (
        <Suspense fallback="Loading...">
            <Outlet />
        </Suspense>
    )
}

export default LoginLayout