import React, { useState } from 'react';
import { Icon } from "@fluentui/react/lib/Icon";
import {
  Persona,
  PersonaSize,
  PersonaPresence,
} from "@fluentui/react/lib/Persona";
import "./Header.css";
import { setUserMetadata } from "../../redux/actions/UI/userMetadata";
import logo from "../../assets/webodhi.png";
import admin from "../../assets/admin.png";
import student from "../../assets/student.png";
import teacher from "../../assets/teacher.png";
import { Tooltip } from "antd";
import LogoutSection from "./logoutSection";
import HelpIcon from '@mui/icons-material/Help';
import HelpSection from "../helpSection";
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import BackupIcon from '@mui/icons-material/Backup';
import DownloadIcon from '@mui/icons-material/Download';
import { MdOutlineBackup } from "react-icons/md";
import { axiosInstance, fetchGet } from '../../API/Baseurl';
import MenuIcon from '@mui/icons-material/Menu';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PatternIcon from '@mui/icons-material/Pattern';
import { openNotification } from '../../utils/CommonFunction';

interface HeaderProps {
  // Define your props here
}

const Header: React.FC<HeaderProps> = (props) => {

  const userMetadata = useSelector((state: any) => state.ui.userMetadata)
  const getUserProfileData = useSelector((state: any) => state.api?.getUserProfile?.data)
  const [callOut, setCallOut] = useState<boolean>(false)
  const [helpOpen, setHelpOpen] = useState<boolean>(false)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const calloutClick = () => {
    setCallOut(!callOut)
  }
  const profileClick = () => {
    navigate("/viewProfile")
    setCallOut(false)
  }
  const changePasswordClick = () => {
    setCallOut(false)
    navigate("/changePassword")
  }

  const clickHelp = () => {
    setHelpOpen(true)
  }

  const clickBackup = async (e: any) => {
    try {
      await fetchGet("SuperAdmin/backup");
      openNotification("topRight", "Backup successfully created", "success");
    } catch (error) {
      openNotification("topRight", "something went wrong", "error");

    }


    e.preventDefault()
  }

  const downloadDatabase = async () => {
    const token = localStorage.getItem("jwt")
    try {
      const response = await axiosInstance({
        url: 'SuperAdmin/download',
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: token ? token : "",
        }
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'application/octet-stream' });

      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'database.db'; // Set the file name

      // Append the link to the body (it's invisible)
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up and remove the link
      link?.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Failed to download the database backup:', error);
    }
  };

  return (
    <div className="headerContainer">
      <div className="leftHeaderContainer">
        <div className="clientLogoBody">
          <img src={logo} alt="logo"></img>
          <div className="clientDetails">
            <h3 className="clientName">
              {userMetadata.InstitutionName}
            </h3>
            <small className="powerdBy">
              Powered by <b>Webodhi</b>
            </small>
          </div>
        </div>
      </div>

      {callOut &&
        <LogoutSection {...{ helpOpen, setHelpOpen, calloutClick, callOut, profileClick, changePasswordClick }} />
      }
      <div className="rightHeaderContainer">
        <div className="mobileRightHeader">
          <MenuIcon onClick={calloutClick} className="menuIcon icon_cursor" />
        </div>

        <div className="largeRightHeader">
          <Tooltip
            title={
              userMetadata.role !== "Student"
                ? "Admin"
                : userMetadata.role === "Student"
                  ? "Student"
                  : "Teacher"
            }
          >
            <img
              src={
                userMetadata.role !== "Student"
                  ? admin
                  : userMetadata.role === "Student"
                    ? student
                    : teacher
              }
              alt="role"
              style={{ height: "20px", marginRight: "10px" }}
              className="persona"
            ></img>
          </Tooltip>
          <div className="userProfile">
            <Persona
              {...{
                imageUrl: "",
                imageInitials: "",
                text: getUserProfileData?.firstName,
                tertiaryText: "In a meeting",
                optionalText: "Available at 4:00pm",
                showSecondaryText: true,
              }}
              size={PersonaSize.size24}
              presence={PersonaPresence.none}
              imageAlt={getUserProfileData?.firstName}
            />
            <div className="view_profile">
              <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <nav aria-label="main mailbox folders">
                  <List className='header_list'>
                    <ListItem disablePadding>
                      <ListItemButton onClick={profileClick}>
                        <ListItemIcon>
                          <AccountBoxIcon />
                        </ListItemIcon>
                        <ListItemText primary="View Profile" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton onClick={changePasswordClick}>
                        <ListItemIcon>
                          <PatternIcon />
                        </ListItemIcon>
                        <ListItemText primary="Change Password" />
                      </ListItemButton>
                    </ListItem>
                    {userMetadata.role === "SuperAdmin" ?
                      <>
                        <ListItem disablePadding>
                          <ListItemButton onClick={clickBackup}>
                            <ListItemIcon>
                              <BackupIcon />
                            </ListItemIcon>
                            <ListItemText primary="Back UP" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton onClick={downloadDatabase}>
                            <ListItemIcon>
                              <DownloadIcon />
                            </ListItemIcon>
                            <ListItemText primary="Download" />
                          </ListItemButton>
                        </ListItem>
                      </>
                      : ""}
                  </List>
                </nav>
              </Box>
            </div>
          </div>
          <div className="help_section_btn">
            <Tooltip title="Help section" > <HelpIcon onClick={clickHelp} /> </Tooltip>
          </div>
          <HelpSection {...{ helpOpen, setHelpOpen }} />
          <div className="logOut">
            <Icon
              iconName="SignOut"
              className="widgetIcons"
              onClick={() => {
                navigate("/")
                localStorage.removeItem("token")
                dispatch(setUserMetadata({
                  username: "",
                  role: "",
                  token: "",
                  isLoggedIn: false,
                  password: ""
                }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;