import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const ProtectedRoute:any = () => {
    const token = localStorage.getItem("token")
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        if (!token) {
            navigate("/")
        }
    }, [location.pathname])
    return token && <Outlet />

};

export default ProtectedRoute;



