import * as type from '../../types/API/types'

export function loginUser(payload: any) {
    return {
        type: type.LOGIN_USER,
        payload: payload
    }
}
export function resetLogin(){
    return{
        type : type.RESET_USER_LOGIN
    }
}

export function refreshToken(payload: any) {
    return {
        type: type.REFRESH_TOKEN,
        payload: payload
    }
}

export function registerUser(payload: any) {
    return {
        type: type.REGISTER_USER,
        payload: payload
    }
}
export function forgotPassword(payload: any) {
    return {
        type: type.FORGOT_PASSWORD,
        payload: payload
    }
}

