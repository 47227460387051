import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decryption, uniqueArrayNewSet } from '../../utils/CommonFunction';
import { useParams } from 'react-router-dom';
import { getSubmission } from '../../redux/actions/API/courses';
import "./index.css"
import PieChart from '../../components/pieChart';
import FilterOptions from './filterOptions';

interface SubmissionProps {
    // Define your props here
}

const Submission: React.FC<SubmissionProps> = (props) => {
    const getSubmissionData = useSelector((state: any) => state?.api?.getSubmission?.data) ?? {}
    const [showndata, setShownData] = useState<any[]>([])
    const [subjectValue, setSubjectValue] = useState<any[]>([])
    const [selectedSubject, setSelectedSubject] = useState<any[]>([])
    const [chapterValue, setChapterValue] = useState<any[]>([])
    const [selectedChapter, setSelectedChapter] = useState<any[]>([])
    const [topicValue, setTopicValue] = useState<any[]>([])
    const [selectedTopic, setSelectedTopic] = useState<any[]>([])
    const [difficultyValue, setDifficultyValue] = useState<any[]>([])
    const [selectedDifficulty, setSelectedDifficulty] = useState<any[]>([])
    const [subjectNamesArr, setSubjectNameArr] = useState<any[]>([])
    const [chapterNamesArr, setChapterNameArr] = useState<any[]>([])
    const [topicNamesArr, setTopicNameArr] = useState<any[]>([])
    const [difficultyNamesArr, setdifficultyNameArr] = useState<any[]>([])

    const param = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        if (getSubmissionData.data) {
            setShownData(getSubmissionData.data)
        }
    }, [getSubmissionData?.data])

    useEffect(() => {
        const course = decryption(param?.courseId)
        const student = decryption(param?.userId)
        const data = {
            courseId: course,
            studentId: student,
            auto: decryption(param?.coourseCode) === "AI" ? 1 : 0
        }
        dispatch(getSubmission(data))
    }, [])

    const removeString = (text: string | number) => {
        if (text) {
            const removeMinus = text.toString().replaceAll("-", "")
            const removePlus = removeMinus.toString().replaceAll("+", "")
            return `-${removePlus}`
        } else {
            return text
        }
    }
    const checkAnswerIndex = (text: number) => {
        if (text === 0 || text === 1 || text === 2 || text === 3) {
            return false
        } else {
            return true
        }
    };
    const checkTextOption = (item: any) => {
        if (item.pageDataJson) {
            const jsonData = JSON.parse(item.pageDataJson)
            if (jsonData?.questionType === "text") {
                if (+item?.selectedOption >= +item.correctOption && +item?.selectedOption <= +jsonData?.rangeTo) {
                    return "correctTextAnswer"
                } else {
                    return "wrongTextAnswer"
                }
            }
        }
    }

    const checkCorrectoption = (selectedAnswer: number, correctAnswer: number, options: any, ind: number, item: any) => {
        let className: string = ''
        if (selectedAnswer >= 0 && selectedAnswer !== null) {
            const compareOption = checkAnswerIndex(selectedAnswer) ? selectedAnswer : ind
            const compareCorrectOption = checkAnswerIndex(selectedAnswer) ? options : ind
            if (selectedAnswer === correctAnswer) {
                if (compareCorrectOption === selectedAnswer) {
                    className = "mcqCorrectSubOption"
                } else {
                    className = "mcqSubOption"
                }
            } else if (selectedAnswer !== correctAnswer) {
                if (compareCorrectOption === correctAnswer) {
                    className = "mcqCorrectSubOption"
                } else if (compareCorrectOption === selectedAnswer) {
                    if (checkAnswerIndex(selectedAnswer)) {
                        if (options === compareOption) {
                            className = "mcqWrongSubOption"
                        } else {
                            className = "mcqSubOption"
                        }
                    } else {
                        className = "mcqWrongSubOption"
                    }
                } else {
                    className = "mcqSubOption"
                }
            }
        } else {
            if (correctAnswer === ind) {
                className = "mcqCorrectSubOption"
            } else {
                className = "mcqSubOption"
            }
            className = "mcqSubOption"
        }
        return className
    }


    const subChange = (e: any, val: any, data: any) => {
        setSelectedSubject([...val])
        const subjectName = val.map((item: any) => item.subject)
        setSubjectNameArr([...subjectName])
        const chapterArray = getSubmissionData.data.filter((item: any) => subjectName.includes(item.subject))
        const selectedChapterArray = selectedChapter.filter((item: any) => subjectName.includes(item.subject))
        const chapterName = selectedChapterArray.map((item: any) => item.chapter)
        setChapterNameArr([...chapterName])
        const topicArray = getSubmissionData.data.filter((item: any) => chapterName.includes(item.chapter))
        const selectedTopicArray = selectedTopic.filter((item: any) => chapterName.includes(item.chapter))
        const topicNames = selectedTopicArray.map((item: any) => item.topic)
        setTopicNameArr([...topicNames])
        if (topicNames.length) {
            const data = getSubmissionData.data.filter((item: any) => topicNames.includes(item.topic))
            if (selectedDifficulty.length) {
                const difficultyNames = selectedDifficulty.map((item: any) => item.difficulty)
                const difficultyData = data.filter((item: any) => difficultyNames.includes(item.difficulty))
                setShownData([...difficultyData])
            } else {
                setShownData([...data])
            }


        } else if (chapterName.length) {
            setShownData([...topicArray])
        } else if (subjectName.length) {
            setShownData([...chapterArray])
        } else {
            setShownData([...getSubmissionData.data])
        }
        setTopicValue([...uniqueArrayNewSet(topicArray, "topic")])
        setSelectedTopic([...selectedTopicArray])
        setChapterValue([...uniqueArrayNewSet(chapterArray, "chapter")])
        setSelectedChapter([...selectedChapterArray])
    }

    const chapterChange = (e: any, val: any[], data: string) => {
        setSelectedChapter([...val])
        const chapterName = val.map((item: any) => item.chapter)
        setChapterNameArr([...chapterName])
        const topicArray = getSubmissionData.data.filter((item: any) => chapterName.includes(item.chapter))
        const selectedTopicArray = selectedTopic.filter((item: any) => chapterName.includes(item.chapter))
        const topicNames = selectedTopicArray.map((item: any) => item.topic)
        setTopicNameArr([...topicNames])

        if (topicNames.length) {
            if (difficultyNamesArr.length) {
                const data = getSubmissionData.data.filter((item: any) => topicNames.includes(item.topic) && difficultyNamesArr.includes(item.difficulty))
                setShownData([...data])
            } else {
                const data = getSubmissionData.data.filter((item: any) => topicNames.includes(item.topic))
                setShownData([...data])
            }
        } else if (chapterName.length) {
            if (difficultyNamesArr.length) {
                const data = topicArray.filter((item: any) => difficultyNamesArr.includes(item.difficulty))
                setShownData([...data])
            } else {
                setShownData([...topicArray])
            }
        } else {
            if (difficultyNamesArr.length) {
                const data = selectedSubject.filter((item: any) => difficultyNamesArr.includes(item.difficulty))
                setShownData([...data])
            } else {
                setShownData([...selectedSubject])
            }
        }
        setTopicValue([...uniqueArrayNewSet(topicArray, "topic")])
        setSelectedTopic([...selectedTopicArray])
    }
    const topicChange = (e: any, val: any[], data: string) => {
        setSelectedTopic([...val])
        const topicNames = val.map((item: any) => item.topic)
        setTopicNameArr([...topicNames])
        if (topicNames.length) {
            const data = getSubmissionData.data.filter((item: any) => topicNames.includes(item.topic))
            setShownData([...data])
        } else {
            setShownData([...selectedChapter])
        }
    }
    const difficultyChange = (e: any, val: any[], data: string) => {
        setSelectedDifficulty([...val])
        const difficultyNames = val.map((item: any) => item.difficulty)
        setdifficultyNameArr([...difficultyNames])
        if (difficultyNames.length) {
            if (selectedTopic.length) {
                const difficultyData = getSubmissionData.data.filter((item: any) => difficultyNames.includes(item.difficulty) && subjectNamesArr.includes(item.subject) && chapterNamesArr.includes(item.chapter) && topicNamesArr.includes(item.topic))
                setShownData([...difficultyData])
            }
            else if (selectedChapter.length) {
                const difficultyData = getSubmissionData.data.filter((item: any) => difficultyNames.includes(item.difficulty) && subjectNamesArr.includes(item.subject) && chapterNamesArr.includes(item.chapter))
                setShownData([...difficultyData])
            } else if (selectedSubject.length) {
                const difficultyData = getSubmissionData.data.filter((item: any) => difficultyNames.includes(item.difficulty) && subjectNamesArr.includes(item.subject))
                setShownData([...difficultyData])
            } else {
                const difficultyData = getSubmissionData.data.filter((item: any) => difficultyNames.includes(item.difficulty))
                setShownData([...difficultyData])
            }
        } else {
            setShownData([...getSubmissionData.data])

        }
    }

    useEffect(() => {
        if (getSubmissionData) {
            if (getSubmissionData?.data?.length) {
                setSubjectValue(uniqueArrayNewSet(getSubmissionData.data, "subject"))
                setDifficultyValue(uniqueArrayNewSet(getSubmissionData.data, "difficulty"))
            }
        }
    }, [getSubmissionData?.data])

    const checkQuestionType = (item: any) => {
        if (item.pageDataJson) {
            const jsonData = JSON.parse(item.pageDataJson)
            return jsonData.questionType
        } else {
            return "single"
        }
    }

    const checkMultipleAnswer = (selectedAnswer: string, correctAnswer: string, options: any, ind: number, item: any) => {
        let correctArray = correctAnswer.split(',');   // Convert to array of numbers
        let selectedArray = selectedAnswer.split(','); // Convert to array of numbers

        const checkCorrect = correctArray.includes(ind.toString())
        const checkSelect = selectedArray.includes(ind.toString())

        if ((checkCorrect && checkSelect) || checkCorrect && !checkSelect) {
            return "mcqCorrectSubOption"
        } else if (checkSelect && !checkCorrect) {
            return "mcqWrongSubOption"
        } else if (!checkCorrect && !checkSelect) {
            return "mcqSubOption"
        } else {
            return "mcqSubOption"
        }
    }

    const showCorrect: any = (item: any, indexs: string) => {
        if (item.correctOption) {
            const selectedArray = item.correctOption.split(",")
            return selectedArray.includes(indexs)
        } else {
            return false
        }
    }

    const showSelectedAnswer: any = (item: any, indexs: string) => {
        if (item.selectedOption) {
            const selectedArray = item.selectedOption.split(",")
            return selectedArray.includes(indexs)
        } else {
            return false
        }
    }

    const showAnswerRange: any = (item: any) => {
        if (item.pageDataJson) {
            const jsonData = JSON.parse(item.pageDataJson)
            return `From ${item.correctOption} To ${jsonData?.rangeTo}`
        }

    }

    return (


        <div className="homeworkElementsContainer">
            <PieChart />
            <div className="studentScoreDetail submission_studentScoreDetail">
                <div className="counter score_detail">
                    <span>Score</span>
                    <h2>{getSubmissionData?.testScore}</h2>
                </div>
                <div className="counter score_detail wrong_correct_score">
                    <div>
                        <span>Marks for Correct Answer</span>
                        <h2>{getSubmissionData?.correctScoring}</h2>
                    </div>
                    <div>
                        <span>Negative Marks</span>
                        <h2>{removeString(getSubmissionData?.wrongScoring)}</h2>
                    </div>
                </div>
            </div>
            <div className="filter_section submission_filter">
                <FilterOptions
                    data={subjectValue}
                    value={selectedSubject}
                    onChange={subChange}
                    name="subject"
                    label="Subject"
                />
                <FilterOptions
                    data={chapterValue}
                    value={selectedChapter}
                    onChange={chapterChange}
                    name="chapter"
                    label="Chapter"
                />
                <FilterOptions
                    data={topicValue}
                    value={selectedTopic}
                    onChange={topicChange}
                    name="topic"
                    label="Topic"
                />
                <FilterOptions
                    data={difficultyValue}
                    value={selectedDifficulty}
                    onChange={difficultyChange}
                    name="difficulty"
                    label="Difficulty Level"
                />
            </div>
            {showndata?.map((item: any, index: number, array: any) => {
                return (
                    <div key={index} className={`${index === (array.length - 1) ? "" : "question_seprator"}`}>
                        <div className="question" >
                            <ul className="sub_details">
                                <li>
                                    <span className='subject_keys'>Difficulty:</span>
                                    <span>{item.difficulty}</span>
                                </li>
                                <li>
                                    <span className='subject_keys'>Subject:</span>
                                    <span>{item.subject}</span>
                                </li>
                                <li>
                                    <span className='subject_keys'>Chapter:</span>
                                    <span>{item.chapter}</span>
                                </li>
                                <li>
                                    <span className='subject_keys'>Topic:</span>
                                    <span>{item.topic}</span>
                                </li>
                            </ul>
                            <div style={{ display: "flex", gap: "6px" }}>
                                <span className='questionText'>{index + 1}.</span>
                                <div className="questionText" dangerouslySetInnerHTML={{ __html: item.questionName }}></div>
                            </div>
                            <div className="question_media">
                                {item?.medias.map((val: any) => {
                                    return (
                                        <img src={val} alt="" />
                                    )
                                })}
                            </div>
                        </div>
                        <div className="mcqSubmission">

                            {
                                checkQuestionType(item) === "text" ? <p><strong> Selected Answer:</strong> <span className={checkTextOption(item)}> {`${item.selectedOption} . ${checkTextOption(item) === "correctTextAnswer" ? "Correct" : "Wrong"}  `}</span></p> : ""
                            }
                            {item.optionInfo?.map((val: any, ind: number) => {
                                return (
                                    <>
                                        {
                                            checkQuestionType(item) === "single" ? <div className={checkCorrectoption(+item.selectedOption, +item.correctOption, val, ind, item)} key={ind} dangerouslySetInnerHTML={{ __html: val }} ></div> : ""
                                        }
                                        {
                                            checkQuestionType(item) === "multiple" ? <div className={checkMultipleAnswer(item.selectedOption, item.correctOption, val, ind, item)} key={ind} dangerouslySetInnerHTML={{ __html: val }} ></div> : ""
                                        }
                                    </>
                                )
                            })}
                            {
                                checkQuestionType(item) === "multiple" ? <div className='multipleCorrect'><strong> Selected Answer:</strong>
                                    <ul className='showCorrectList'>
                                        {
                                            item.optionInfo.map((elem: any, indexs: number) => {
                                                return (
                                                    showSelectedAnswer(item, indexs.toString()) ?
                                                        <li>{elem}</li> : ""
                                                )
                                            })
                                        }
                                    </ul>
                                </div> : ""
                            }
                            {
                                checkQuestionType(item) === "multiple" ? <div className='multipleCorrect'><strong> Correct Answer:</strong>
                                    <ul className='showCorrectList'>
                                        {
                                            item.optionInfo.map((elem: any, indexs: number) => {
                                                return (
                                                    showCorrect(item, indexs.toString()) ?
                                                        <li>{elem}</li> : ""
                                                )
                                            })
                                        }
                                    </ul>
                                </div> : ""

                            }
                            {
                                checkQuestionType(item) === "text" ? <div style={{ display: "flex", gap: "5px", marginTop: 10 }}> <strong>Correct Answer:</strong> {showAnswerRange(item)}  </div> : ""
                            }
                            {checkQuestionType(item) === "single" ?
                                <div style={{ display: "flex", gap: "5px", marginTop: 10 }}> <strong>Correct Answer:</strong> {checkQuestionType(item) === "text" ? item?.correctOption : item.optionInfo[item?.correctOption]}  </div>
                                : ""}
                            {item.explanation ? <div style={{ display: "flex", gap: "5px", marginTop: 10 }}> <strong>Explanation:</strong> <div dangerouslySetInnerHTML={{ __html: item?.explanation }} ></div> </div> : ""}

                        </div>

                    </div>
                )
            })}
        </div>
    );
};

export default Submission;