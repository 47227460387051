import { PREVIOUS_LOCATION_DATA } from "../types/UI/appLocation";
import { IAction } from "./userMetadataReducer";

const initialState = {
    pathname: "/",
    showCourse: false,
}


const previousLocationData = (state = initialState, action: IAction) => {
    switch (action.type) {
        case PREVIOUS_LOCATION_DATA:
            return action.payload
        default:
            return state
    }
}

export default previousLocationData;