import { call, delay, put, select, takeEvery } from "redux-saga/effects";
import jwt_decode from "jwt-decode";
import firebase from "../../firebase"
import { getDatabase, push, ref, set, update } from 'firebase/database';
import {
  ADD_DEPARTMENT, ADD_DEPARTMENT_FAILED, ADD_DEPARTMENT_SUCCESS, GET_DEPARTMENT_CLASSES, GET_DEPARTMENT_CLASSES_FAILED, GET_DEPARTMENT_CLASSES_SUCCESS,
  LOGIN_USER, ADD_CLASS, ADD_CLASS_SUCCESS, ADD_CLASS_FAILED, GET_TEACHER_COURSES, GET_TEACHER_COURSES_SUCCESS, GET_TEACHER_COURSES_FAILED,
  ADD_COURSE, ADD_COURSE_SUCCESS, ADD_COURSE_FAILED, GET_COURSE_PAGES_SUCCESS,
  GET_COURSE_PAGES_FAILED, GET_COURSE_PAGES, EDIT_COURSE, EDIT_COURSE_SUCCESS, EDIT_COURSE_FAILED, DELETE_COURSE, DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAILED, ADD_HEIRARCHY, ADD_HEIRARCHY_SUCCESS, ADD_HEIRARCHY_FAILED, ADD_REGULAR_PAGE, ADD_QUESTION_PAGE, ADD_REGULAR_PAGE_SUCCESS,
  ADD_REGULAR_PAGE_FAILED, ADD_QUESTION_PAGE_SUCCESS, ADD_QUESTION_PAGE_FAILED, EDIT_DEPARTMENT_SUCCESS, EDIT_DEPARTMENT_FAILED, EDIT_DEPARTMENT,
  DELETE_DEPARTMENT, DELETE_DEPARTMENT_SUCCESS, DELETE_DEPARTMENT_FAILED, EDIT_CLASS, EDIT_CLASS_SUCCESS, EDIT_CLASS_FAILED, REGISTER_USER,
  REGISTER_USER_SUCCESS, REGISTER_USER_FAILED, GET_PAGES, GET_PAGES_SUCCESS, GET_PAGES_FAILED, DELETE_PAGE_SUCCESS, DELETE_PAGE_FAILED,
  DELETE_HEIRARCHY_SUCCESS, DELETE_HEIRARCHY_FAILED, DELETE_PAGE, DELETE_HEIRARCHY, GET_STUDENTS_BY_CLASS, GET_STUDENTS_BY_CLASS_SUCCESS,
  GET_STUDENTS_BY_CLASS_FAILED, DELETE_USER_SUCCESS, DELETE_USER_FAILED, DELETE_USER, GET_STUDENT_COURSES_SUCCESS, GET_STUDENT_COURSES_FAILED,
  GET_STUDENT_COURSES, VIEW_SUBMISSION_SUCCESS, VIEW_SUBMISSION_FAILED, VIEW_SUBMISSION, GET_ANALYTIC_SUCCESS,
  GET_ANALYTIC_FAILED, GET_ANALYTIC, SUBMIT_QUIZ_SUCCESS, SUBMIT_QUIZ_FAILED, SUBMIT_QUIZ, REFRESH_TOKEN, REFRESH_TOKEN_FAILED, DELETE_CLASS,
  DELETE_CLASS_SUCCESS, DELETE_CLASS_FAILED, REFRESH_TOKEN_SUCCESS, UPDATE_QUESTION_PAGE, UPDATE_QUESTION_PAGE_SUCCESS, UPDATE_QUESTION_PAGE_FAILED,
  UPDATE_REGULAR_PAGE_SUCCESS, UPDATE_REGULAR_PAGE_FAILED, UPDATE_REGULAR_PAGE, GET_SUBMISSION_SUCCESS, GET_SUBMISSION_FAILED, GET_SUBMISSION,
  GET_USER_INFO_SUCCESS, GET_USER_INFO_FAILED, GET_USER_INFO, UPDATE_USER_INFO_SUCCESS, UPDATE_USER_INFO, GET_COURSE_DETAIL, GET_COURSE_DETAIL_SUCCESS,
  GET_COURSE_DETAIL_FAILED, ENABLE_ANALYTIC_SUCCESS, ENABLE_ANALYTIC_FAILED, ENABLE_ANALYTIC, GET_COURSE_PAGES_NEW_SUCCESS, GET_COURSE_PAGES_NEW_FAILED,
  GET_COURSE_PAGES_NEW, EDIT_HEIRARCHY, MOVE_PAGE_SUCCESS, MOVE_PAGE_FAILED, MOVE_PAGE, MOVE_FOLDER_SUCCESS, MOVE_FOLDER_FAILED, MOVE_FOLDER,
  CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILED, CHANGE_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD, CONVERT_PAGE_SUCCESS, CONVERT_PAGE_FAILED, CONVERT_PAGE, UPDATE_PUBLISH_STATUS_SUCCESS, UPDATE_PUBLISH_STATUS_FAILED, UPDATE_PUBLISH_STATUS,
  ADD_PAGE_SUCCESS,
  ADD_PAGE_FAILED,
  ADD_PAGE,
  UPDATE_PAGE_SUCCESS,
  UPDATE_PAGE_FAILED,
  UPDATE_PAGE,
  GET_AI_COURSE_SUCCESS,
  GET_AI_COURSE_FAILED,
  GET_AI_COURSE,
  GET_TOPIC,
  GET_TOPIC_FAILED,
  GET_TOPIC_SUCCESS,
  ADD_TOPIC_SUCCESS,
  ADD_TOPIC_FAILED,
  ADD_TOPIC,
  GET_QUESTION_BANK,
  GET_QUESTION_BANK_SUCCESS,
  GET_QUESTION_BANK_FAILED,
  EDIT_CONFIG,
  EDIT_CONFIG_SUCCESS,
  EDIT_CONFIG_FAILED,
  GET_HELP_SUCCESS,
  GET_HELP_FAILED,
  GET_HELP,
  GET_INSTITUTIONS,
  GET_INSTITUTIONS_SUCCESS,
  GET_INSTITUTIONS_FAILED,
  ADD_INSTITUTION_SUCCESS,
  ADD_INSTITUTION_FAILED,
  ADD_INSTITUTION,
  EDIT_INSTITUTION_SUCCESS,
  EDIT_INSTITUTION,
  EDIT_INSTITUTION_FAILED,
  DELETE_INSTITUTION_SUCCESS,
  DELETE_INSTITUTION_FAILED,
  DELETE_INSTITUTION
} from "../types/API/types";
import { notification } from "antd";
import { ACTION_LOADER, LOADER_STATUS } from "../types/UI/spinner";
import { APP_BREADCRUMB, SET_APP_LOCATION } from "../types/UI/appLocation";
import { ALL_COURSES, SET_USER_METADATA } from "../types/UI/userMetadata";
import { studentCourse } from "../../utils/routePath";
import { checkAi, updateArray } from "../../utils/CommonFunction";


export const openNotification = (
  status,
  message,
) => {
  notification[status]({
    message: status,
    description: message,
    placement: "topRight",
  });
};

const actionLoaderDataTrue = {
  type: ACTION_LOADER,
  payload: { spinnerStatus: true }
}
const actionLoaderDataFalse = {
  type: ACTION_LOADER,
  payload: { spinnerStatus: false }
}
const loaderStatusTrue = {
  type: LOADER_STATUS,
  payload: { spinnerStatus: true }
}
const loaderStatusFalse = {
  type: LOADER_STATUS,
  payload: { spinnerStatus: false }
}

const token = (data) => {
  const decoded_token = jwt_decode(data)
  return decoded_token.nameid
}

export const loginUser = (state) => state.api.login;
export const loginUserData = (state) => state.ui.userMetadata;
// var API_URL = process.env.REACT_APP_API_URL;
var API_URL = process.env.REACT_APP_API_URL;


function* getApiWorker(endpoint, apiToken) {
  var url = API_URL + endpoint;
  return yield fetch(url, {
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken,
    }),
    method: "GET",
  }).then((response) => {
    if (!response.ok) {
      const error = new Error(response.statusText);
      throw error;
    }
    return response.json();
  });
}

function* postApiWorker(endpoint, requestBody, apiToken) {
  var url = API_URL + endpoint;
  return yield fetch(url, {
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken,
      redirect: 'manual',
    }),
    method: "POST",
    redirect: 'manual',
    body: JSON.stringify(requestBody),
  }).then((response) => {
    if (!response.ok) {
      const error = new Error(response.statusText);
      throw error;
    }
    return response.json();
  });
}

function* deleteApiWorker(endpoint, requestBody, apiToken) {
  var url = API_URL + endpoint;
  return yield fetch(url, {
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken,
    }),
    method: "DELETE",
    body: JSON.stringify(requestBody),
  }).then((response) => {
    if (!response.ok) {
      const error = new Error(response.statusText);
      throw error;
    }
    return response.json();
  });
}

function* login(requestBody) {
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      "Login/Login",
      requestBody.payload
    );
    if (response.result < 0) {
      openNotification("error", response?.errorMessage)
    } else {
      let decoded_token = jwt_decode(response.token)
      localStorage.user = decoded_token.nameid
      localStorage.setItem("token", decoded_token.nameid)
      localStorage.setItem("jwt", response.token)
      const offlineData = localStorage.getItem("offlineData")
      if (!offlineData) {
        const setOfflineData = {
          addPageData: [],
          updatePageData: []
        }
        localStorage.setItem("offlineData", JSON.stringify(setOfflineData))
      }

      const data = {
        username: decoded_token.unique_name,
        role: decoded_token.role,
        token: response.token,
        isLoggedIn: true,
        InstitutionName: decoded_token.InstitutionName,
        institutionId: decoded_token.institutionId,
        password: "",
        id: decoded_token.nameid
      }
      yield put({
        type: SET_USER_METADATA,
        payload: data,
      });

      yield put({
        type: "LOGIN_USER_SUCCESS",
        payload: { responseData: response },
      });
    }
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: "LOGIN_USER_FAILED", payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* refreshToken(requestBody) {
  try {
    const response = yield call(postApiWorker, "Login/Login", requestBody.payload);
    yield put({ type: REFRESH_TOKEN_SUCCESS, payload: { responseData: response } });

  }
  catch (error) {
    openNotification("error", error?.message)
    yield put({ type: REFRESH_TOKEN_FAILED, payload: { error: error } });
  }
}

function* setStoragevalue(token, courseId, response) {
  const decoded_token = jwt_decode(token)
  const localStorageValue = yield select(state => state.ui.allCoursesData);

  if ((Object.keys(localStorageValue).length)) {
    const parseData = localStorageValue
    if (parseData[decoded_token.nameid]) {
      parseData[decoded_token.nameid][courseId] = response
      yield put({ type: ALL_COURSES, payload: parseData });

    } else {
      parseData[decoded_token.nameid] = {}
      parseData[decoded_token.nameid][courseId] = response
      yield put({ type: ALL_COURSES, payload: parseData });
    }
  } else {
    const parseData = {}
    parseData[decoded_token.nameid] = {}
    parseData[decoded_token.nameid][courseId] = response
    yield put({ type: ALL_COURSES, payload: parseData });
  }
}

function* getCoursePagesNew(requestBody) {
  try {
    var apiToken = yield select(loginUser);
    let response
    yield put(loaderStatusTrue)
    response = yield call(
      getApiWorker,
      `CreatorStudios/GetCoursePagesNew?CourseId=${requestBody.payload.courseId}`,
      apiToken.data.token
    );

    yield setStoragevalue(apiToken.data.token, requestBody.payload.courseId, response)

    yield put({
      type: GET_COURSE_PAGES_NEW_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_COURSE_PAGES_NEW_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}
function* getAiPagesNew(requestBody) {
  try {
    var apiToken = yield select(loginUser);
    let response
    yield put(loaderStatusTrue)
    response = yield call(
      getApiWorker,
      `CreatorStudios/GetAICoursePages?CourseId=${requestBody.payload.courseId}`,
      apiToken.data.token
    );

    yield setStoragevalue(apiToken.data.token, requestBody.payload.courseId, response)
    yield put({
      type: GET_AI_COURSE_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_AI_COURSE_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}
function* addTopic(requestBody) {
  try {
    var apiToken = yield select(loginUser);
    let response
    yield put(loaderStatusTrue)
    response = yield call(
      postApiWorker,
      `CreatorStudios/AddTopics`,
      requestBody.payload,
      apiToken.data.token
    );

    yield put({
      type: ADD_TOPIC_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: ADD_TOPIC_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}
function* getQuestionBank(requestBody) {
  try {
    var apiToken = yield select(loginUser);
    let response
    yield put(loaderStatusTrue)
    response = yield call(
      postApiWorker,
      `CreatorStudios/GetQuestionBankPages`,
      requestBody.payload,
      apiToken.data.token
    );

    yield put({
      type: GET_QUESTION_BANK_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_QUESTION_BANK_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}
function* getTopics() {
  try {
    var apiToken = yield select(loginUser);
    let response
    yield put(loaderStatusTrue)
    response = yield call(
      getApiWorker,
      `CreatorStudios/GetTopics`,
      apiToken.data.token
    );

    yield put({
      type: GET_TOPIC_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_TOPIC_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}
function* addHeirarchy(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      `CreatorStudios/AddHierarchy?ParentHierarchyId=${requestBody.payload.hirarchydata.ParentHierarchyId}&IndexName=${requestBody.payload.hirarchydata.IndexName}&CourseId=${requestBody.payload.hirarchydata.CourseId}`,
      {},
      apiToken.data.token
    );
    yield put({
      type: ADD_HEIRARCHY_SUCCESS,
      payload: { responseData: response },
    });
    const requestBodyForGetCourse = {
      payload: { courseId: requestBody.payload.courseId },
      type: "GET_COURSE_PAGES_NEW"
    }
    yield getCoursePagesNew(requestBodyForGetCourse)
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", `${requestBody.payload.hirarchydata.IndexName} added Successfully`)
    }
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: ADD_HEIRARCHY_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}
function* editHeirarchy(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      `CreatorStudios/EditHierarchy`,
      requestBody.payload.data,
      apiToken.data.token
    );
    yield put({
      type: ADD_HEIRARCHY_SUCCESS,
      payload: { responseData: response },
    });
    const requestBodyForGetCourse = {
      payload: { courseId: requestBody.payload.courseId },
      type: "GET_COURSE_PAGES_NEW"
    }
    yield getCoursePagesNew(requestBodyForGetCourse)
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    openNotification("success", "Folder Updated Successfully")
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: ADD_HEIRARCHY_FAILED, payload: { error: error } });
  }
  finally {
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
  }
}
function* deleteHeirarchy(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      deleteApiWorker,
      `CreatorStudios/DeleteHierarchy?HierarchyLevel=${requestBody.payload.id}`,
      {},
      apiToken.data.token
    );
    yield put({
      type: DELETE_HEIRARCHY_SUCCESS,
      payload: { responseData: response },
    });
    const requestBodyForGetCourse = {
      payload: { courseId: requestBody.payload.courseId },
      type: "GET_COURSE_PAGES_NEW"
    }
    yield getCoursePagesNew(requestBodyForGetCourse)
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    openNotification("success", "Hierarchy Deleted Successfully")
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: DELETE_HEIRARCHY_FAILED, payload: { error: error } });
  } finally {
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
  }
}

function* addRegularPage(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      `CreatorStudios/AddRegularPage`,
      requestBody.payload.data,
      apiToken.data.token
    );
    yield put({
      type: ADD_REGULAR_PAGE_SUCCESS,
      payload: { responseData: response },
    });
    const requestBodyForGetCourse = {
      payload: { courseId: requestBody.payload.courseId },
      type: "GET_COURSE_PAGES_NEW"
    }
    yield getCoursePagesNew(requestBodyForGetCourse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Regular Page added Successfully")
    }
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: ADD_REGULAR_PAGE_FAILED, payload: { error: error } });
  } finally {
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
  }
}
function* addPages(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      `CreatorStudios/AddPage`,
      requestBody.payload.data,
      apiToken.data.token
    );
    yield put({
      type: ADD_PAGE_SUCCESS,
      payload: { responseData: response },
    });
    if (requestBody.payload.type === "questions") {
      const requestBodyForGetCourse = {
        payload: { "startRow": 0 },
        type: GET_QUESTION_BANK
      }
      yield getQuestionBank(requestBodyForGetCourse)
    } else {
      const requestBodyForGetCourse = {
        payload: { courseId: requestBody.payload.courseId, auto: requestBody.payload.auto },
        type: "GET_COURSE_PAGES_NEW"
      }
      yield getCoursePagesNew(requestBodyForGetCourse)

    }
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Page added Successfully")
    }
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: ADD_PAGE_FAILED, payload: { error: error } });
  } finally {
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
  }
}
function* updatePages(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      `CreatorStudios/UpdatePage`,
      requestBody.payload.data,
      apiToken.data.token
    );
    yield put({
      type: UPDATE_PAGE_SUCCESS,
      payload: { responseData: response },
    });
    if (requestBody.payload.type === "questions") {
      const requestBodyForGetCourse = {
        payload: { "startRow": 0 },
        type: GET_QUESTION_BANK
      }
      yield getQuestionBank(requestBodyForGetCourse)
    } else {
      const requestBodyForGetCourse = {
        payload: { courseId: requestBody.payload.courseId },
        type: "GET_COURSE_PAGES_NEW"
      }
      yield getCoursePagesNew(requestBodyForGetCourse)

    }
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Page added Successfully")
    }
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: UPDATE_PAGE_FAILED, payload: { error: error } });
  } finally {
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
  }
}

function* addQuestionPage(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      `CreatorStudios/AddQuestionPage`,
      requestBody.payload.data,
      apiToken.data.token
    );
    yield put({
      type: ADD_QUESTION_PAGE_SUCCESS,
      payload: { responseData: response },
    });

    const storageCourse = yield select(state => state.ui.allCoursesData);

    const courseresponse = yield call(
      getApiWorker,
      "CreatorStudios/GetCoursePagesNew?CourseId=" + requestBody.payload.courseId,
      apiToken.data.token
    );
    if (Object.keys(storageCourse).length) {
      const parseData = storageCourse
      const decoded_token = token(apiToken.data.token)
      const localArrays = parseData[decoded_token][requestBody.payload.courseId]
      const activeData = courseresponse.filter(obj2 => !localArrays.some(obj1 => obj1.pageId === obj2.pageId))
      localStorage.setItem("activeData", JSON.stringify(activeData))
    }
    yield setStoragevalue(apiToken.data.token, requestBody.payload.courseId, courseresponse)
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Question Page added Successfully")
    }

  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: ADD_QUESTION_PAGE_FAILED, payload: { error: error } });
  } finally {
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
  }
}

function* updateQuestionPage(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      "CreatorStudios/UpdateQuestionpage",
      requestBody.payload.data,
      apiToken.data.token
    );
    yield put({
      type: UPDATE_QUESTION_PAGE_SUCCESS,
      payload: { responseData: response },
    });
    const requestBodyForGetCourse = {
      payload: { courseId: requestBody.payload.courseId },
      type: "GET_COURSE_PAGES_NEW"
    }
    yield getCoursePagesNew(requestBodyForGetCourse)

    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Question Page Updated Successfully")
    }
  } catch (error) {
    yield put({ type: UPDATE_QUESTION_PAGE_FAILED, payload: { error: error } });
    openNotification("error", error?.message)
  } finally {
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
  }
}
function* updateRegularPage(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      `CreatorStudios/UpdateRegularPage?PageId=${requestBody.payload.data2.PageId}&CourseId=${requestBody.payload.data2.CourseId}&PublishStatus=${requestBody.payload.data2.PublishStatus}&PageHierarchy=${requestBody.payload.data2.PageHierarchy}&PageDataJson=${requestBody.payload.data2.PageDataJson}&Order=${requestBody.payload.data2.order}&AddedFiles=${requestBody.payload.data2.addedFiles}&DeletedFiles${requestBody.payload.data2.deletedFiles}`,
      requestBody.payload.data2,
      apiToken.data.token
    );
    yield put({
      type: UPDATE_REGULAR_PAGE_SUCCESS,
      payload: { responseData: response },
    });
    const requestBodyForGetCourse = {
      payload: { courseId: requestBody.payload.courseId },
      type: "GET_COURSE_PAGES_NEW"
    }
    yield getCoursePagesNew(requestBodyForGetCourse)
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Regular Page Updated Successfully")
    }
  } catch (error) {
    yield put({ type: UPDATE_REGULAR_PAGE_FAILED, payload: { error: error } });
    openNotification("error", error?.message)
  } finally {
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
  }
}
function* convertPage(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      `CreatorStudios/ConvertPageType`,
      requestBody.payload.convertPageData,
      apiToken.data.token
    );
    yield put({
      type: CONVERT_PAGE_SUCCESS,
      payload: { responseData: response },
    });
    const requestBodyForGetCourse = {
      payload: { courseId: requestBody.payload.courseId },
      type: "GET_COURSE_PAGES_NEW"
    }
    yield getCoursePagesNew(requestBodyForGetCourse)
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Regular Page Updated Successfully")
    }
  } catch (error) {
    yield put({ type: CONVERT_PAGE_FAILED, payload: { error: error } });
    openNotification("error", error?.message)
  } finally {
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
  }
}


function* deletePage(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      deleteApiWorker,
      `CreatorStudios/DeletePage`,
      { pageId: (requestBody.payload.pageObj.Pageid).toString() },
      apiToken.data.token
    );
    yield put({
      type: DELETE_PAGE_SUCCESS,
      payload: { responseData: response },
    });
    if (requestBody.payload.type === "questions") {
      const requestBodyForGetCourse = {
        payload: { "startRow": 0 },
        type: GET_QUESTION_BANK
      }
      yield getQuestionBank(requestBodyForGetCourse)
    } else {
      const requestBodyForGetCourse = {
        payload: { courseId: requestBody.payload.courseId },
        type: "GET_COURSE_PAGES_NEW"
      }
      yield getCoursePagesNew(requestBodyForGetCourse)
    }
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    openNotification("success", "Page Deleted Successfully")
  } catch (error) {
    yield put({ type: DELETE_PAGE_FAILED, payload: { error: error } });
    openNotification("error", error?.message)
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}
function* movePage(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      `Admin/MovePage`,
      requestBody.payload.pageObj,
      apiToken.data.token
    );
    yield put({
      type: MOVE_PAGE_SUCCESS,
      payload: { responseData: response },
    });
    const requestBodyForGetCourse = {
      payload: { courseId: requestBody.payload.courseId },
      type: "GET_COURSE_PAGES_NEW"
    }
    yield getCoursePagesNew(requestBodyForGetCourse)
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Page moved SuccessFully")
    }
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: MOVE_PAGE_FAILED, payload: { error: error } });
  } finally {
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
  }
}
function* moveFolder(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      `Admin/MoveFolder`,
      requestBody.payload.folderObj,
      apiToken.data.token
    );
    yield put({
      type: MOVE_FOLDER_SUCCESS,
      payload: { responseData: response },
    });
    const requestBodyForGetCourse = {
      payload: { courseId: requestBody.payload.courseId },
      type: "GET_COURSE_PAGES_NEW"
    }
    yield getCoursePagesNew(requestBodyForGetCourse)
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Folder moved SuccessFully")
    }
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: MOVE_FOLDER_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* addDepartment(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  yield put(actionLoaderDataTrue)
  try {
    const response = yield call(
      postApiWorker,
      "Admin/AddDepartment",
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: ADD_DEPARTMENT_SUCCESS,
      payload: { responseData: response },
    });
    yield getDepartmentClasses()
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Department Added Successfully.");
    }
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: ADD_DEPARTMENT_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* editDepartment(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  yield put(actionLoaderDataTrue)
  try {
    const response = yield call(
      postApiWorker,
      "Admin/EditDepartment",
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: EDIT_DEPARTMENT_SUCCESS,
      payload: { responseData: response },
    });
    yield getDepartmentClasses()
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Department Updated Successfully.");
    }
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: EDIT_DEPARTMENT_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* deleteDepartment(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  yield put(actionLoaderDataTrue)
  try {
    const response = yield call(
      deleteApiWorker,
      "Admin/DeleteDepartment",
      requestBody.payload,
      apiToken.data.token,
      "Delete"
    );
    yield put({
      type: DELETE_DEPARTMENT_SUCCESS,
      payload: { responseData: response },
    });
    yield getDepartmentClasses()
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    openNotification("success", "Department Deleted Successfully.");
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: DELETE_DEPARTMENT_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* addClass(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  yield put(actionLoaderDataTrue)
  try {
    const response = yield call(
      postApiWorker,
      "Admin/AddClass",
      // "Admin/EditConfig",
      requestBody.payload,
      apiToken.data.token
    );
    yield put({ type: ADD_CLASS_SUCCESS, payload: { responseData: response } });
    yield getDepartmentClasses()
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Class Added Successfully.");
    }
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: ADD_CLASS_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}
function* editConfig(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  yield put(actionLoaderDataTrue)
  try {
    const response = yield call(
      postApiWorker,
      "Admin/EditConfig",
      requestBody.payload,
      apiToken.data.token
    );
    yield put({ type: EDIT_CONFIG_SUCCESS, payload: { responseData: response } });
    yield getDepartmentClasses()
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Config updated successfully.");
    }
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: EDIT_CONFIG_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* editClass(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  yield put(actionLoaderDataTrue)
  try {
    const response = yield call(
      postApiWorker,
      "Admin/EditClass",
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: EDIT_CLASS_SUCCESS,
      payload: { responseData: response },
    });
    yield getDepartmentClasses()
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Class Updated Successfully.");
    }
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: EDIT_CLASS_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}
function* deleteClass(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  yield put(actionLoaderDataTrue)
  try {
    const response = yield call(
      deleteApiWorker,
      `Admin/DeleteClass`,
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: DELETE_CLASS_SUCCESS,
      payload: { responseData: response },
    });
    yield getDepartmentClasses()
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
    openNotification("success", "Class deleted successfully");
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: DELETE_CLASS_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* addCourse(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  yield put(actionLoaderDataTrue)
  try {
    const response = yield call(
      postApiWorker,
      `CreatorStudios/AddCourse`,
      requestBody.payload.data,
      apiToken.data.token
    );
    yield put({
      type: ADD_COURSE_SUCCESS,
      payload: { responseData: response },
    });
    if (response.result) {
      yield getStudentCourses()
      yield put(actionLoaderDataFalse)
      yield put(loaderStatusFalse)
      if (response.errorMessage) {
        openNotification("error", "Something went wrong")
      } else {
        openNotification("success", "Course Added Successfully")
      }
    } else {
      yield put({ type: ADD_COURSE_FAILED, payload: { error: response.errorMessage } });
      openNotification("error", response.errorMessage)
    }
  } catch (error) {
    yield put({ type: ADD_COURSE_FAILED, payload: { error: error } });
    openNotification("error", error?.message)
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* editCourse(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      "CreatorStudios/EditCourse",
      requestBody.payload.data,
      apiToken.data.token
    );
    yield put({
      type: EDIT_COURSE_SUCCESS,
      payload: { responseData: response },
    });
    if (response.result = "Success") {
      const courseData = yield select(state => state.api.getStudentCourses.data.courses);
      const currentIndex = courseData.findIndex((item) => item.courseId === requestBody.payload.data.courseId)
      courseData[currentIndex].allowedAttempts = requestBody.payload.data.allowedAttempts
      courseData[currentIndex].classIds = requestBody.payload.data.classIds
      courseData[currentIndex].courseCode = requestBody.payload.data.courseCode
      courseData[currentIndex].courseDataJson = requestBody.payload.data.courseDataJson
      courseData[currentIndex].courseDescription = requestBody.payload.data.courseDescription
      courseData[currentIndex].courseId = requestBody.payload.data.courseId
      courseData[currentIndex].courseName = requestBody.payload.data.courseName
      courseData[currentIndex].courseType = requestBody.payload.data.courseType
      courseData[currentIndex].publishStatus = requestBody.payload.data.publishStatus
      courseData[currentIndex].teacherIds = requestBody.payload.data.teacherIds

      const dataObj = {
        courses: courseData
      }
      yield put({
        type: GET_STUDENT_COURSES_SUCCESS,
        payload: { responseData: dataObj },
      });
      yield getStudentCourses()
      openNotification("success", "Course updated successfully")
    } else {
      openNotification("error", "Something went wrong")
    }
    // yield getStudentCourses()
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
    // if (response.errorMessage) {
    //   openNotification("error", "Something went wrong")
    // } else {
    //   openNotification("success", "Course updated successfully")
    // }
  } catch (error) {
    yield put({ type: EDIT_COURSE_FAILED, payload: { error: error } });
    openNotification("error", "Something went wrong")
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* updatePublishStatus({ payload }) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      `Admin/SetPublishStatus?CourseId=${payload.publishData.CourseId}&PublishStatus=${payload.publishData.PublishStatus}&Task=${payload.publishData.Task ? payload.publishData.Task : null}`,
      {},
      apiToken.data.token
    );
    yield put({
      type: UPDATE_PUBLISH_STATUS_SUCCESS,
      payload: { responseData: response },
    });
    if (response.result === "Success") {
      if (payload.name === "updateAnalytic") {
        yield getAnalytic({ payload: payload.analyticData })
      } else {
        const courseData = yield select(state => state.api.getStudentCourses.data.courses);
        const currentIndex = courseData.findIndex((item) => item.courseId === payload.analyticData.courseId)
        courseData[currentIndex].publishStatus = +(payload.publishData.PublishStatus)
        const dataObj = {
          courses: courseData
        }
        yield put({
          type: GET_STUDENT_COURSES_SUCCESS,
          payload: { responseData: dataObj },
        });
        // yield getCourses({ payload: { classId: payload.analyticData.classId } })
        // yield getStudentCourses()
      }
      if (response.errorMessage) {
        openNotification("error", "Something went wrong")
      } else {
        openNotification("success", "Publish status has been updated.")
      }
    } else {
      openNotification("error", response.errorMessage)
    }
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
  } catch (error) {
    yield put({ type: UPDATE_PUBLISH_STATUS_FAILED, payload: { error: error } });
    openNotification("error", "Something went wrong")
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* deleteCourse(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(actionLoaderDataTrue)
    yield put(loaderStatusTrue)
    const response = yield call(
      deleteApiWorker,
      `CreatorStudios/DeleteCourse`,
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: DELETE_COURSE_SUCCESS,
      payload: { responseData: response },
    });
    const decoded_token = jwt_decode(apiToken.data.token)
    const localStorageValue = yield select(state => state.ui.allCoursesData);
    if (Object.keys(localStorageValue).length) {
      const parseData = localStorageValue
      if (parseData[decoded_token.nameid]) {
        delete parseData[decoded_token.nameid][requestBody.payload.courseId]
        yield put({ type: ALL_COURSES, payload: parseData });
      }
    }
    yield getStudentCourses()
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    openNotification("success", "Course Deleted Successfully")

  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: DELETE_COURSE_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}


function* getAnalytic(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  try {
    const response = yield call(
      postApiWorker,
      `Admin/GetAnalytics`,
      requestBody.payload,
      apiToken.data.token
    );
    const scoreData = localStorage.getItem("scoreboard")
    const checkData = () => {
      if (scoreData) {
        return JSON.parse(scoreData)
      } else {
        return []
      }
    }
    let addCourseId
    if (response.userScoringInfo.length) {
      addCourseId = response.userScoringInfo.map((item) => {
        item.courseId = requestBody.payload.courseId
        return item
      })
    } else {
      addCourseId = []
    }
    const addOrUpdate = (newArray, existingArray) => {
      newArray.forEach(newObj => {
        const index = existingArray.findIndex(obj => ((obj.userId === newObj.userId) && (obj.courseId === newObj.courseId) && (obj.classId === newObj.classId)));
        if (index !== -1) {
          existingArray[index] = newObj;
        } else {
          existingArray.push(newObj);
        }
      });
      return existingArray
    }

    localStorage.setItem("scoreboard", JSON.stringify(addOrUpdate(addCourseId, checkData())))
    yield put({
      type: GET_ANALYTIC_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_ANALYTIC_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}

function* GetCourseDetails(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    const response = yield call(
      getApiWorker,
      `Student/GetCourseDetails?StudentId=${requestBody.payload.studentId}`,
      apiToken.data.token
    );
    yield put({
      type: GET_COURSE_DETAIL_SUCCESS,
      payload: { responseData: response },
    });
    yield put(loaderStatusFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_COURSE_DETAIL_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}

function* viewSubmission(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  try {
    const response = yield call(
      postApiWorker,
      `Student/GetAnswers`,
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: VIEW_SUBMISSION_SUCCESS,
      payload: { responseData: response },
    });
    yield put(loaderStatusFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: VIEW_SUBMISSION_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}
function* getsubmission(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  try {
    const response = yield call(
      postApiWorker,
      `Admin/GetSubmission`,
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: GET_SUBMISSION_SUCCESS,
      payload: { responseData: response },
    });
    yield put(loaderStatusFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_SUBMISSION_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}

function* getDepartmentClasses() {
  const usermetaData = yield select(state => state.ui.userMetadata) || []
  yield put(loaderStatusTrue)
  try {
    var apiToken = yield select(loginUser);
    yield put(loaderStatusTrue)
    const response = yield call(
      getApiWorker,
      "Admin/GetDepartmentsClasses",
      apiToken.data.token
    );
    yield put({
      type: GET_DEPARTMENT_CLASSES_SUCCESS,
      payload: { responseData: response },
    });
    yield put(loaderStatusFalse)
    const allTeacher = yield select(state => state.api.getTeacherCourses.data) || []

    response.map(async (item) => {
      if (item.classes.length) {

        const databaseFunc = firebase.database();
        // const messagesRef = databaseFunc.ref('groups');
        const messagesRef = databaseFunc.ref(`institute/${usermetaData.InstitutionName}/groups`);

        // Fetch existing groups once
        const snapshot = await messagesRef.once('value');
        const data = snapshot.val();
        const messagesArray = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
        const includedData = item.classes.map((val) => val.classId)
        const filteredData = messagesArray.filter(item => !includedData.includes(item.classId));
        for (let i = 0; i < filteredData.length; i++) {
          const element = filteredData[i];
          const channelRef = messagesRef.child(element.id);
          channelRef.remove().then(() => {
            console.log('Channel deleted successfully.');
          })
            .catch((error) => {
              console.error('Error deleting channel:', error);
            });
        }

        item.classes.map((val) => {
          const filterData = messagesArray.findIndex((item) => item.classId === val.classId);
          if (filterData === -1) { // Group does not exist, create it
            const newGroupRef = messagesRef.push();
            newGroupRef.set({
              name: `${val.classGrade}-${val.section} class`,
              messages: {},
              classId: val.classId,
              type: "group"
            });
          }
        });
      }
    });

  } catch (error) {
    openNotification("error", error?.message)
    yield put({
      type: GET_DEPARTMENT_CLASSES_FAILED,
      payload: { error: error },
    });
    yield put(loaderStatusFalse)
  }
}

function* getTeacherCourses() {
  yield put(loaderStatusTrue)
  try {
    var apiToken = yield select(loginUser);
    const response = yield call(
      postApiWorker,
      "Admin/GetTeachers",
      {},
      apiToken.data.token
    );
    yield put({
      type: GET_TEACHER_COURSES_SUCCESS,
      payload: { responseData: response },
    });
    yield put(loaderStatusFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_TEACHER_COURSES_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}

const returnStudentVal = (allTeacherId, allStudentId, name) => {
  if (name === "student") {
    return allTeacherId || []
  } else {
    return allStudentId || []
  }
}

function* updateGroup(responseData, name, classId) {
  const allStudent = yield select(state => state.api.getStudentsByClass.data) || []
  const allTeacher = yield select(state => state.api.getTeacherCourses.data) || []
  const usermetaData = yield select(state => state.ui.userMetadata) || []
  allStudent.map(item => item.role = "Student")
  allTeacher.map(item => item.role = "Teacher")
  const getDatabaseFunc = getDatabase();
  const databaseFunc = firebase.database();
  // const messagesRef = databaseFunc.ref(`groups`);
  const messagesRef = databaseFunc.ref(`institute/${usermetaData.InstitutionName}/groups`);

  // const usersRef = databaseFunc.ref(`users`);
  // usersRef.once('value', (snapshot) => {
  //   const data = snapshot.val();
  //   const usersArray = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];      // setMessages(messagesArray);
  //   const filterUsers = usersArray?.findIndex((item) => item.classId === classId)
  //   if (filterUsers === -1) {
  //     const userRef = push(ref(getDatabaseFunc, 'users'));
  //     set(userRef, {
  //       participants: responseData,
  //       classId: classId,
  //       type: "users"
  //     });
  //   } else {
  //     const usersRef = ref(getDatabaseFunc, `users/${usersArray[filterUsers].id}`);
  //     const updatedParticipants = [...responseData];
  //     update(usersRef, { participants: updatedParticipants });
  //   }
  // })

  messagesRef.once('value', (snapshot) => {
    const data = snapshot.val();
    const messagesArray = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];      // setMessages(messagesArray);
    const filterData = messagesArray.findIndex((item) => item.classId === classId)
    if (filterData >= 0) {
      const groupRef = ref(getDatabaseFunc, `institute/${usermetaData.InstitutionName}/groups/${messagesArray[filterData].id}`);
      const updatedParticipants = [...returnStudentVal(allTeacher, allStudent, name), ...responseData];
      update(groupRef, { participants: updatedParticipants });
    } else {
      const newGroupRef = push(ref(getDatabaseFunc, `institute/${usermetaData.InstitutionName}/groups`));
      set(newGroupRef, {
        name: `${classId} class`,
        classId: classId,
        messages: {},
        type: "group"
      });
    }
  });
}

function* getStudentsByClass(requestBody) {
  try {
    var apiToken = yield select(loginUser);
    yield put(loaderStatusTrue)
    const response = yield call(
      postApiWorker,
      "Admin/GetStudentByClass",
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: GET_STUDENTS_BY_CLASS_SUCCESS,
      payload: { responseData: response },
    });
    yield updateGroup(response, "student", requestBody.payload.classId)

    yield put(loaderStatusFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({
      type: GET_STUDENTS_BY_CLASS_FAILED,
      payload: { error: error },
    });
  } finally {
    yield put(loaderStatusFalse)
  }
}


function* getStudentCourses() {
  try {
    var apiToken = yield select(loginUser);
    yield put(loaderStatusTrue)
    const response = yield call(
      getApiWorker,
      "Student/GetCourses",
      apiToken.data.token
    );
    yield put({
      type: GET_STUDENT_COURSES_SUCCESS,
      payload: { responseData: response },
    });
    const decoded_token = token(apiToken.data.token)
    const allCourses = yield select(state => state.ui.allCoursesData);
    if (Object.keys(allCourses).length) {

      const parseData = allCourses
      if (parseData[decoded_token]) {
        const courseIds = Object.keys(parseData[decoded_token])
        for (let i = 0; i < courseIds.length; i++) {
          const element = courseIds[i];
          if (response.courses.some(obj => obj.courseId === +(element))) {
          } else {
            delete parseData[decoded_token][element]
            yield put({ type: ALL_COURSES, payload: parseData });
          }
        }
      }
    }
    yield put(loaderStatusFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_STUDENT_COURSES_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}


function* getCoursePages(requestBody) {
  try {
    var apiToken = yield select(loginUser);
    yield put(loaderStatusTrue)
    const response = yield call(
      getApiWorker,
      "CreatorStudios/GetCoursePages?CourseId=" + requestBody.payload.courseId,
      apiToken.data.token
    );
    yield put({
      type: GET_COURSE_PAGES_SUCCESS,
      payload: { responseData: response },
    });
    yield put(loaderStatusFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_COURSE_PAGES_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}
function* getPages(requestBody) {

  try {
    var apiToken = yield select(loginUser);
    yield put(loaderStatusTrue)
    const response = yield call(
      getApiWorker,
      "Student/GetStudentCoursePages?CourseId=" + requestBody.payload.courseId,
      apiToken.data.token
    );
    yield put({ type: GET_PAGES_SUCCESS, payload: { responseData: response } });
    yield put(loaderStatusFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_PAGES_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}


function* EnableAnalytic(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  yield put(actionLoaderDataTrue)
  try {
    const response = yield call(
      postApiWorker,
      `Admin/EnableRetry`,
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: ENABLE_ANALYTIC_SUCCESS,
      payload: { responseData: response },
    });
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "You have successfully enabled submission")
    }
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: ENABLE_ANALYTIC_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}
function* getUserProfile(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    const response = yield call(
      postApiWorker,
      `Admin/GetUserInfo`,
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: GET_USER_INFO_SUCCESS,
      payload: { responseData: response },
    });
    yield put(loaderStatusFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_USER_INFO_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}


function* submitQuiz(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      `Student/SubmitTestCourse`,
      requestBody.payload,
      apiToken.data.token
    );
    let count = localStorage.getItem("errorCount") || 0

    const checkResponse = "score" in response
    if (response.errorMessage) {

      if (count < 10) {
        localStorage.setItem("errorCount", +count + 1)
        function randomIntFromInterval(min, max) { // min and max included 
          return Math.floor(Math.random() * (max - min + 1) + min)
        }
        count += 1
        const rndInt = randomIntFromInterval(100, 1000)
        yield delay(rndInt);
        yield put({
          type: SUBMIT_QUIZ,
          payload: requestBody.payload,
        });
        localStorage.setItem("submitData", JSON.stringify(requestBody.payload))
      } else {
        // yield put({
        //   type: APP_BREADCRUMB,
        //   payload: [{
        //     path: "/",
        //     name: "Dashboard",
        //     showCourse: false
        //   }]
        // })
        // yield put({
        //   type: SET_APP_LOCATION,
        //   payload: "/"
        // })
        window.location.href = studentCourse
        localStorage.setItem("errorCount", 0)
        openNotification("error", "Something went wrong")
      }
    }
    else {
      localStorage.setItem("submitData", "")
      yield put({
        type: APP_BREADCRUMB,
        payload: [{
          path: "/",
          name: "Dashboard",
          showCourse: false
        }]
      })
      yield put({
        type: SET_APP_LOCATION,
        payload: "/"
      })
      yield put({
        type: SUBMIT_QUIZ_SUCCESS,
        payload: { responseData: response },
      });
      yield put(loaderStatusFalse)
      yield put(actionLoaderDataFalse)
      localStorage.setItem("errorCount", 0)
      openNotification("success", "Test is successfully submitted")
    }
  } catch (error) {
    openNotification("error", error?.message)
    let count = localStorage.getItem("errorCount") || 0

      if (count < 10) {
        localStorage.setItem("errorCount", +count + 1)
        function randomIntFromInterval(min, max) { // min and max included 
          return Math.floor(Math.random() * (max - min + 1) + min)
        }
        count += 1
        const rndInt = randomIntFromInterval(100, 1000)
        yield delay(rndInt);
        yield put({
          type: SUBMIT_QUIZ,
          payload: requestBody.payload,
        });
        localStorage.setItem("submitData", JSON.stringify(requestBody.payload))
      } else {
        // yield put({
        //   type: APP_BREADCRUMB,
        //   payload: [{
        //     path: "/",
        //     name: "Dashboard",
        //     showCourse: false
        //   }]
        // })
        // yield put({
        //   type: SET_APP_LOCATION,
        //   payload: "/"
        // })
        window.location.href = studentCourse
        localStorage.setItem("errorCount", 0)
        openNotification("error", "Something went wrong")
      }
    yield put({ type: SUBMIT_QUIZ_FAILED, payload: { error: error } });
  } finally {

    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)

  }
}

function* registerUser(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(actionLoaderDataTrue)
  yield put(loaderStatusTrue)
  try {
    const response = yield call(
      postApiWorker,
      "Login/Register",
      requestBody.payload.data,
      apiToken.data.token
    );
    yield put({
      type: REGISTER_USER_SUCCESS,
      payload: { responseData: response },
    });
    if (response.result > 0) {
      if (requestBody.payload.classId) {
        const response = yield call(
          postApiWorker,
          "Admin/GetStudentByClass",
          { classId: requestBody.payload.classId },
          apiToken.data.token
        );
        yield put({
          type: GET_STUDENTS_BY_CLASS_SUCCESS,
          payload: { responseData: response },
        });
        yield put(actionLoaderDataFalse)
        openNotification("success", "Student added successfully")

      } else {
        const response = yield call(
          postApiWorker,
          "Admin/GetTeachers",
          {},
          apiToken.data.token
        );
        yield put({
          type: GET_TEACHER_COURSES_SUCCESS,
          payload: { responseData: response },
        });
        yield put(actionLoaderDataFalse)
        openNotification("success", "Teacher added successfully")
      }
    } else {
      openNotification("error", response?.errorMessage)
    }
    yield put(loaderStatusFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: REGISTER_USER_FAILED, payload: { error: error } });
  } finally {
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
  }
}
function* forgotPassword(requestBody) {
  yield put(actionLoaderDataTrue)

  yield put(loaderStatusTrue)
  try {
    const response = yield call(
      postApiWorker,
      "Login/ForgetPassword",
      requestBody.payload,
    );
    yield put({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: { responseData: response },
    });
    if (!response.errorMessage) {
      openNotification("success", "Password has been reset. Please check Email.")
    } else {
      openNotification("error", response.errorMessage)
    }
    yield put(loaderStatusFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: REGISTER_USER_FAILED, payload: { error: error } });
  } finally {
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)
  }
}


function* updateUserprofile(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(actionLoaderDataTrue)
  yield put(loaderStatusTrue)
  try {
    const response = yield call(
      postApiWorker,
      `Login/EditUser`,
      requestBody.payload.data,
      apiToken.data.token
    );
    yield put({
      type: UPDATE_USER_INFO_SUCCESS,
      payload: { responseData: response },
    });

    if (response.result == 'Success') {
      if (requestBody.payload.classId) {
        const response = yield call(
          postApiWorker,
          "Admin/GetStudentByClass",
          { classId: requestBody.payload.classId },
          apiToken.data.token
        );
        yield put({
          type: GET_STUDENTS_BY_CLASS_SUCCESS,
          payload: { responseData: response },
        });
        yield put(actionLoaderDataFalse)
      } else {
        const response = yield call(
          postApiWorker,
          "Admin/GetTeachers",
          {},
          apiToken.data.token
        );
        yield put({
          type: GET_TEACHER_COURSES_SUCCESS,
          payload: { responseData: response },
        });
        yield put(actionLoaderDataFalse)
      }
      if (response.errorMessage) {
        openNotification("error", "Something went wrong")
      } else {
        openNotification("success", "User updated successfully")
      }
    } else {
      openNotification("error", response.errorMessage)
    }
    yield put(loaderStatusFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: UPDATE_QUESTION_PAGE_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* deleteUser(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  yield put(actionLoaderDataTrue)
  try {
    const response = yield call(
      deleteApiWorker,
      `Login/DeleteUser`,
      { userId: requestBody.payload.userId },
      apiToken.data.token
    );
    if (!response.errorMessage) {
      yield put({
        type: DELETE_USER_SUCCESS,
        payload: { responseData: response },
      });
      if (requestBody.payload.classId) {
        const classBody = {
          payload: { classId: requestBody.payload.classId },
          type: GET_STUDENTS_BY_CLASS
        }
        yield getStudentsByClass(classBody)
      } else {
        yield getTeacherCourses()
      }
      openNotification("success", "User Deleted Successfully")
    } else {
      openNotification("error", response.errorMessage)
    }

    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)

  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: DELETE_USER_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}


function* ChangePassword(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  yield put(actionLoaderDataTrue)
  try {
    yield put(loaderStatusTrue)
    const response = yield call(
      postApiWorker,
      `Login/ChangePassword`,
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: { responseData: response },
    });
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    if (response.errorMessage) {
      openNotification("error", "Something went wrong")
    } else {
      openNotification("success", "Password have been changed successfully")
    }
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: CHANGE_PASSWORD_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* getHelp() {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    const response = yield call(
      getApiWorker,
      `Admin/GetHelp`,
      apiToken.data.token
    );
    yield put({
      type: GET_HELP_SUCCESS,
      payload: { responseData: response },
    });
    yield put(loaderStatusFalse)
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_HELP_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}


function* getInstitutions() {
  try {
    var apiToken = yield select(loginUser);
    console.log('apiToken::::::::::::', apiToken)
    let response
    yield put(loaderStatusTrue)
    response = yield call(
      postApiWorker,
      `SuperAdmin/GetInstitutions`,
      {},
      apiToken.data.token
    );

    yield put({
      type: GET_INSTITUTIONS_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: GET_INSTITUTIONS_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
  }
}

function* addInstitution(requestBody) {
  var apiToken = yield select(loginUser);
  yield put(loaderStatusTrue)
  yield put(actionLoaderDataTrue)
  try {
    const response = yield call(
      postApiWorker,
      `SuperAdmin/AddInstitution`,
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: ADD_INSTITUTION_SUCCESS,
      payload: { responseData: response },
    });
    if (response.result) {
      yield getInstitutions()
      yield put(actionLoaderDataFalse)
      yield put(loaderStatusFalse)
      if (response.errorMessage) {
        openNotification("error", "Something went wrong")
      } else {
        openNotification("success", "Institution Added Successfully")
      }
    } else {
      yield put({ type: ADD_INSTITUTION_FAILED, payload: { error: response.errorMessage } });
      openNotification("error", response.errorMessage)
    }
  } catch (error) {
    yield put({ type: ADD_INSTITUTION_FAILED, payload: { error: error } });
    openNotification("error", error?.message)
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* editInstitution(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(loaderStatusTrue)
    yield put(actionLoaderDataTrue)
    const response = yield call(
      postApiWorker,
      "SuperAdmin/EditInstitution",
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: EDIT_INSTITUTION_SUCCESS,
      payload: { responseData: response },
    });
    if (response.result = "Success") {
      yield getInstitutions()

      openNotification("success", "Institution updated successfully")
    } else {
      openNotification("error", "Something went wrong")
    }
    yield put(actionLoaderDataFalse)
    yield put(loaderStatusFalse)

  } catch (error) {
    yield put({ type: EDIT_INSTITUTION_FAILED, payload: { error: error } });
    openNotification("error", "Something went wrong")
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}

function* deleteInstitution(requestBody) {
  var apiToken = yield select(loginUser);
  try {
    yield put(actionLoaderDataTrue)
    yield put(loaderStatusTrue)
    const response = yield call(
      deleteApiWorker,
      `SuperAdmin/DeleteInstitution`,
      requestBody.payload,
      apiToken.data.token
    );
    yield put({
      type: DELETE_INSTITUTION_SUCCESS,
      payload: { responseData: response },
    });

    yield getInstitutions()
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
    openNotification("success", "Institution Deleted Successfully")

  } catch (error) {
    openNotification("error", error?.message)
    yield put({ type: DELETE_INSTITUTION_FAILED, payload: { error: error } });
  } finally {
    yield put(loaderStatusFalse)
    yield put(actionLoaderDataFalse)
  }
}


function* studebookSagas() {
  yield takeEvery(LOGIN_USER, login);
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(MOVE_PAGE, movePage);
  yield takeEvery(MOVE_FOLDER, moveFolder);
  yield takeEvery(GET_DEPARTMENT_CLASSES, getDepartmentClasses);
  yield takeEvery(ADD_DEPARTMENT, addDepartment);
  yield takeEvery(EDIT_DEPARTMENT, editDepartment);
  yield takeEvery(DELETE_DEPARTMENT, deleteDepartment);
  yield takeEvery(ADD_CLASS, addClass);
  yield takeEvery(EDIT_CONFIG, editConfig);
  yield takeEvery(EDIT_CLASS, editClass);
  yield takeEvery(GET_TEACHER_COURSES, getTeacherCourses);
  // yield takeEvery(GET_COURSES, getCourses);
  yield takeEvery(GET_STUDENT_COURSES, getStudentCourses);
  yield takeEvery(ADD_COURSE, addCourse);
  yield takeEvery(EDIT_COURSE, editCourse);
  yield takeEvery(UPDATE_PUBLISH_STATUS, updatePublishStatus);
  yield takeEvery(GET_ANALYTIC, getAnalytic);
  yield takeEvery(VIEW_SUBMISSION, viewSubmission);
  yield takeEvery(GET_SUBMISSION, getsubmission);
  yield takeEvery(DELETE_COURSE, deleteCourse);
  yield takeEvery(GET_COURSE_PAGES, getCoursePages);
  yield takeEvery(GET_COURSE_PAGES_NEW, getCoursePagesNew);
  yield takeEvery(GET_AI_COURSE, getAiPagesNew);
  yield takeEvery(GET_TOPIC, getTopics);
  yield takeEvery(GET_QUESTION_BANK, getQuestionBank);
  yield takeEvery(ADD_TOPIC, addTopic);
  yield takeEvery(ADD_HEIRARCHY, addHeirarchy);
  yield takeEvery(EDIT_HEIRARCHY, editHeirarchy);
  yield takeEvery(ADD_REGULAR_PAGE, addRegularPage);
  yield takeEvery(ADD_PAGE, addPages);
  yield takeEvery(UPDATE_PAGE, updatePages);
  yield takeEvery(ADD_QUESTION_PAGE, addQuestionPage);
  yield takeEvery(REGISTER_USER, registerUser);
  yield takeEvery(UPDATE_QUESTION_PAGE, updateQuestionPage);
  yield takeEvery(UPDATE_REGULAR_PAGE, updateRegularPage);
  yield takeEvery(CONVERT_PAGE, convertPage);
  yield takeEvery(GET_PAGES, getPages);
  yield takeEvery(DELETE_PAGE, deletePage);
  yield takeEvery(DELETE_HEIRARCHY, deleteHeirarchy);
  yield takeEvery(GET_STUDENTS_BY_CLASS, getStudentsByClass);
  yield takeEvery(DELETE_USER, deleteUser);
  yield takeEvery(SUBMIT_QUIZ, submitQuiz);
  yield takeEvery(REFRESH_TOKEN, refreshToken);
  yield takeEvery(DELETE_CLASS, deleteClass);
  yield takeEvery(GET_USER_INFO, getUserProfile);
  yield takeEvery(UPDATE_USER_INFO, updateUserprofile);
  yield takeEvery(GET_COURSE_DETAIL, GetCourseDetails);
  yield takeEvery(ENABLE_ANALYTIC, EnableAnalytic);
  yield takeEvery(CHANGE_PASSWORD, ChangePassword);
  yield takeEvery(GET_HELP, getHelp);
  yield takeEvery(GET_INSTITUTIONS, getInstitutions);
  yield takeEvery(ADD_INSTITUTION, addInstitution);
  yield takeEvery(EDIT_INSTITUTION, editInstitution);
  yield takeEvery(DELETE_INSTITUTION, deleteInstitution);


}

export default studebookSagas;
