import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { decryptText, timeFormat } from '../../utils/CommonFunction';
import "./index.css"
import { fetchPost, postAPiCall } from '../../API/Baseurl';
import { useDispatch } from 'react-redux';
import { registerUser } from '../../redux/actions/API/login';
import Button from '../../components/Common/Button/Button';
import Success from './successScreen';
import Pending from './pendingScreen';
import FailedScreen from './failedScreen';

interface PaymentSuccessProps {
    // Define your props here
}

const PaymentSuccess: React.FC<PaymentSuccessProps> = () => {

    const [paramsValue, setParamsValue] = useState<any>({})
    const [paymentStatus, setPaymentStatus] = useState<string>("")
    const [paymentData, setPaymentData] = useState<any[]>([])
    const [count, setCount] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    // PENDING
    // FAILED
    // SUCCESS

    const fetchData = async () => {
        const payloadData = localStorage.getItem("registerData")
        // const decode = decryptText(payloadData)
        // const url = new URL(`http://localhost:3000/payment_success?${decode}`)
        // const params: any = {};
        // url.searchParams.forEach((value, key) => {
        //     params[key] = value;
        // });
        if (payloadData) {
            const params = JSON.parse(payloadData)
            try {
                setLoading(true)
                const result = await fetchPost(`Payment/GetOrderDetails?orderId=${params.order_id}`, {}, atob(params.token))
                setPaymentStatus(result[0].payment_status)
                if (result[0].payment_status === "SUCCESS") {

                }
                setPaymentData(result)
            } catch (error) {

            } finally {
                setLoading(false)
            }

            // const userData = {
            //     roleId: 3,
            //     firstName: params.firstName,
            //     middleName: params.middleName,
            //     lastName: params.lastName,
            //     dateOfBirth: timeFormat(params.dateOfBirth),
            //     personalAddress: "NA",
            //     phoneNumber: params.phoneNumber,
            //     emailId: params.emailId,
            //     userAccountStatus: 1,
            //     classId: +(atob(params.classId))
            // }
            // setParamsValue(params)
            // const response = await fetchPost("/Login/Register", userData, atob(params.token))
            // console.log("responseresponse", response);
            // if (response.ok) {
            //     const data = await response.json();
            //     console.log('API response data:', data);
            // }
        }
    }

    useEffect(() => {
        const statusChange = setTimeout(() => {
            if (paymentStatus === "PENDING") {
                setCount(count + 1)
                fetchData()
            }
        }, 10000);
        return () => clearTimeout(statusChange)
    }, [paymentStatus, count])


    useEffect(() => {
        fetchData()
    }, [])

    const successClick = async () => {
        const payloadData = localStorage.getItem("registerData")
        if (payloadData) {
            const params = JSON.parse(payloadData)
            const userData = {
                roleId: 3,
                firstName: params.firstName,
                middleName: params.middleName,
                lastName: params.lastName,
                dateOfBirth: timeFormat(params.dateOfBirth),
                personalAddress: "NA",
                phoneNumber: params.phoneNumber,
                emailId: params.emailId,
                userAccountStatus: 1,
                classId: +(atob(params.classId))
            }
            setParamsValue(params)
            const response = await fetchPost("Login/Register", userData, atob(params.token))
            // if (response?.result === "Success") {
            //     navigate("/dashboard")
            // }
            console.log("responseresponse", response);
            // if (response.ok) {
            //     const data = await response.json();
            //     console.log('API response data:', data);
            // }
        }

    }

    return (
        loading ? <p> Loader...</p> :

            <>
                {paymentStatus === "SUCCESS" ?

                    <div className='addStudentContainer'>
                        <div className="successfull_body">
                            <Success onClick={successClick} />
                        </div>
                    </div> : ""}
                {paymentStatus === "FAILED" ?

                    <div className='addStudentContainer'>
                        <div className="successfull_body">
                            <FailedScreen />
                        </div>
                    </div> : ""}
                {paymentStatus === "PENDING" ?

                    <div className='addStudentContainer'>
                        <div className="successfull_body">
                            <Pending />
                        </div>
                    </div> : ""}
            </>
    );
};

export default PaymentSuccess