import { IAction } from "../../reducers/userMetadataReducer";
import { PREVIOUS_LOCATION_DATA } from "../../types/UI/appLocation";

export interface previousLocationDataProps {
    pathname: string,
    showCourse: boolean,
    
}

export function previousLocationData(payload: previousLocationDataProps ): IAction {
    return  {
        type: PREVIOUS_LOCATION_DATA,
        payload: payload
    }
}