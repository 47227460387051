import { APP_BREADCRUMB, APP_PREVIOUS_LOCATION, SCREEN_PROPS, SET_APP_LOCATION } from "../types/UI/appLocation";


const initialState = [{
    path: "/",
    name :"Dashboard",
    showCourse : false
}]
const initialScreenProps = {}
const initialStatelocation = {
    pathname: "/",
}


export interface IAction {
    type: string;
    payload?: any;
}

export const appLocationReducer = (state = initialStatelocation, action: IAction) => {
    switch (action.type) {
        case SET_APP_LOCATION:
            return action.payload
        default:
            return state
    }
}
export const appPreviousLocationReducer = (state = initialStatelocation, action: IAction) => {
    switch (action.type) {
        case APP_PREVIOUS_LOCATION:
            return action.payload
        default:
            return state
    }
}
export const appAppBreadCrumb = (state = initialState, action: IAction) => {
    switch (action.type) {
        case APP_BREADCRUMB:
            return action.payload
        default:
            return state
    }
}
export const setScreenProps = (state = initialScreenProps, action: IAction) => {
    switch (action.type) {
        case SCREEN_PROPS:
            return action.payload
        default:
            return state
    }
}
// export function setScreenProps(payload: breadCrumb): IAction {
//     return {
//         type: SCREEN_PROPS,
//         payload: payload
//     }
// }
// export default appLocationReducer;