import { ALL_COURSES, SET_USER_METADATA, UNSAVED_CHANGES } from "../types/UI/userMetadata";

const initialState = {
    username: null,
    role: null,
    token: null,
    isLoggedIn: false,
    InstitutionName: "",
    showdashboard: true
}

export interface IAction {
    type: string;
    payload?: any;
}

const userMetaDataReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SET_USER_METADATA:
            return action.payload
        default:
            return state
    }
}
export const setUnsavedChangesDataReducer = (state = {}, action: IAction) => {
    switch (action.type) {
        case UNSAVED_CHANGES:
            return action.payload
        default:
            return state
    }
}
export const setAllCoursesDataReducer = (state = {}, action: IAction) => {
    switch (action.type) {
        case ALL_COURSES:
            return action.payload
        default:
            return state
    }
}

export default userMetaDataReducer;