import React from 'react';
import { PendingImg } from '../../utils/ImagePath';

interface PendingProps {
    // Define your props here
}

const Pending: React.FC<PendingProps> = (props) => {
    return (
        <>
            <PendingImg />
            <div className='success_text_section'>
                <h1>Payment Pending</h1>
                <p>Your payment is pending</p>
            </div>
        </>
    );
};

export default Pending;