
import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import logo from "../../assets/webodhi_logo.png";
import { TextField } from "office-ui-fabric-react";
import "./index.css";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginSchema } from '../../utils/validationSchema';
import { loginUser } from '../../redux/actions/API/login';
import Button from '../../components/Common/Button/Button';
import { classes, studentCourse, institutions } from '../../utils/routePath';
import jwt_decode from "jwt-decode";
import { closedEye, openEye } from '../../utils/ImagePath';

interface LoginProps {
}

const Login: React.FC<LoginProps> = (props) => {

  const dispatch = useDispatch()
  const location = useLocation()
  const [loginError, setLoginError] = useState<boolean>(false)
  const [hideStatus, setHideStatus] = useState<boolean>(false)
  const navigate = useNavigate()
  const loginData = useSelector((state: any) => state.api.login)
  const prevLoginValueRef = useRef<{ loginData: any | null }>({ loginData: null });
  const Intial = {
    username: "",
    password: ""
  };


  const { handleChange, handleSubmit, handleBlur, values, errors, touched } = useFormik({
    initialValues: Intial,
    validationSchema: LoginSchema,
    onSubmit: () => loginApiCall(),
  });

  const submitLogin = (e: any) => {
    e.preventDefault()
    handleSubmit()
  }
  const forgotClick = () => {
    navigate("/forgotpassword")
  }

  useEffect(() => {
    if (prevLoginValueRef.current.loginData) {
      if (prevLoginValueRef.current.loginData !== loginData) {
        if (loginData.error) {
          setLoginError(true)
        } else {
          setLoginError(false)
        }
        if (!loginData.error && !loginData.loading) {
          let decoded_token: any = jwt_decode(loginData.data.token)
          navigate(decoded_token.role === "Student" ? studentCourse : decoded_token.role === 'SuperAdmin'? institutions: classes)
        }
      }
    } else {
      prevLoginValueRef.current.loginData = loginData
    }

  }, [prevLoginValueRef.current, loginData]);
  const loginApiCall = () => {
    const clientToken = localStorage.getItem("clientToken")
    try {
      dispatch(loginUser({
        username: values.username,
        password: values.password,
        deviceID: clientToken
      }))

    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token) {
      let decoded_token: any = jwt_decode(loginData.data.token)
      navigate(decoded_token.role === "Student" ? studentCourse : decoded_token.role === 'SuperAdmin'? institutions: classes)
    }
  }, [location.pathname])

  const passwordHideShow = () => {
    setHideStatus(!hideStatus)
  }

  return (
    <form onSubmit={submitLogin} className="wrapper">
      <div className="form-signin">
        <div className="logoBody">
          <img src={logo} alt="logo"></img>
        </div>
        <h3 className="form-signin-heading">Sign in</h3>
        {loginError && (
          <p className="error loginError">Invalid Credentials</p>
        )}
        <TextField
          placeholder="Username"
          className="loginField"
          name='username'
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={(touched.username && errors.username) ? errors.username : ""}
        />

        <div className="password_feild">
          <TextField
            placeholder="Password"
            className="loginField"
            name='password'
            type={hideStatus ? "text" : "password"}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={(touched.password && errors.password) ? errors.password : ""}
          />
          <img className="password_eye" onClick={passwordHideShow} src={hideStatus ? openEye : closedEye} alt="" />
        </div>
        <div className="loginSubmitBtn">
          <Button btnText="Login" className="btn btn-lg btn-primary btn-block login_btn" types="submit" />
          <div className='login_btn_section'>
            <p onClick={forgotClick}>Forgot Password</p>

          </div>
        </div>
      </div>
    </form>
  )
};

export default Login;