import React from 'react';
import Loader from '../Common/Loader/Loader';
import "./index.css"

interface SuspenseLoaderProps {
  // Define your props here
}

const SuspenseLoader: React.FC<SuspenseLoaderProps> = (props) => {
  return (
    <div className='homeworkElementsContainer loadingScreen'>
        <Loader/>
    </div>
  );
};

export default SuspenseLoader;