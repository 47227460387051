import * as type from '../types/API/types'
import { IAction } from './userMetadataReducer'

const initialState = {
    data: null,
    loading: false,
    error: null,
}

export const getDepartmentClassesReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_DEPARTMENT_CLASSES:
            return {
                ...state,
                loading: true,
            }
        case type.GET_DEPARTMENT_CLASSES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_DEPARTMENT_CLASSES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const addDepartmentReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.ADD_DEPARTMENT:
            return {
                ...state,
                loading: true,
            }
        case type.ADD_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.ADD_DEPARTMENT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const editDepartmentReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.EDIT_DEPARTMENT:
            return {
                ...state,
                loading: true,
            }
        case type.EDIT_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.EDIT_DEPARTMENT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const deleteDepartmentReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.DELETE_DEPARTMENT:
            return {
                ...state,
                loading: true,
                error: null
            }
        case type.DELETE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null
            }
        case type.DELETE_DEPARTMENT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const addClassReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.ADD_CLASS:
            return {
                ...state,
                loading: true,
            }
        case type.ADD_CLASS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.ADD_CLASS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const editClassReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.EDIT_CLASS:
            return {
                ...state,
                loading: true,
            }
        case type.EDIT_CLASS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.EDIT_CLASS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const deleteClass = (state = initialState, action: IAction)=>{
    switch(action.type) {
        case type.DELETE_CLASS:
            return {
                ...state,
                loading: true,
                error : null
            }
        case type.DELETE_CLASS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error : null
            }
        case type.DELETE_CLASS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}