import { TextField } from "office-ui-fabric-react";
import React from "react";

interface SetUpNewPasswordProps {
    
}
 
interface SetUpNewPasswordState {
    
}
 
class SetUpNewPassword extends React.Component<SetUpNewPasswordProps, SetUpNewPasswordState> {
    // constructor(props: SetUpNewPasswordProps) {
    //     super(props);
    // }
    render() { 
        return ( <div className="wrapper">
        <div className="form-signin">
            <h4 className="setUpPasswordLabel">Set new password</h4>
            <TextField
                placeholder="New Password"
                className="loginField"
                required={true}
                type="password"
                errorMessage={""}
            />
            <TextField
                placeholder="Confirm Password"
                className="loginField"
                required={true}
                type="password"
                errorMessage={""}
            />
            <div className="resetPasswordButton">
                <button className="btn btn-lg btn-primary btn-block login_btn" type="submit" onClick={() => {}}>Reset Password</button>
            </div>
        </div>
    </div>);
    }
}
 
export default SetUpNewPassword;