import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './index.css'

interface HelpAccordionProps {
    title: any,
    content: any,
    searchTerm: string,
    index:number
}

const HelpAccordion: React.FC<HelpAccordionProps> = ({ title, content, searchTerm , index }) => {
    return (
        <div key={index}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {searchTerm ?
                        content : <div dangerouslySetInnerHTML={{ __html: content }}></div>}
                </AccordionDetails>
            </Accordion>

        </div>
    );
};

export default HelpAccordion;