import * as type from '../types/API/types'
import { IAction } from './userMetadataReducer'

const initialState = {
    data: [],
    loading: false,
    error: null,
}



export const getInstitutions = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_INSTITUTIONS:
            return {
                ...state,
                loading: true,
            }
        case type.GET_INSTITUTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_INSTITUTIONS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}