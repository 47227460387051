import * as type from '../types/API/types'
import { IAction } from './userMetadataReducer'

const initialState = {
    data: null,
    loading: false,
    error: null,
}
const initialStudent = {
    data: [],
    loading: false,
    error: null,
}

export const getTeacherCoursesReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_TEACHER_COURSES:
            return {
                ...state,
                loading: true,
            }
        case type.GET_TEACHER_COURSES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_TEACHER_COURSES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const getStudentsByClassReducer = (state = initialStudent, action: IAction) => {
    switch(action.type) {
        case type.GET_STUDENTS_BY_CLASS:
            return {
                ...state,
                loading: true,
            }
        case type.GET_STUDENTS_BY_CLASS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_STUDENTS_BY_CLASS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}


