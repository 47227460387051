import { ACTION_LOADER, LOADER_STATUS, OFFLINE_PAGE_DATA, SPINNER_STATUS } from "../types/UI/spinner";


const initialState = {
    spinnerStatus: false
}
const initialPageData:any = {
    addPageData:[],
    updatePageData:[]
}

export interface IAction {
    type: string;
    payload?: any;
}

const spinnerReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SPINNER_STATUS:
            return action.payload
        default:
            return state
    }
}
export const loaderReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case LOADER_STATUS:
            if(action.payload.spinnerStatus){
                localStorage.setItem("time" , "30")
            }else{
                localStorage.setItem("time" , "0")
            }
            return action.payload
        default:
            return state
    }
}
export const actionLoaderReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case ACTION_LOADER:
            return action.payload
        default:
            return state
    }
}
export const offlinePageData = (state = initialPageData, action: IAction) => {
    switch (action.type) {
        case OFFLINE_PAGE_DATA:
            return action.payload
        default:
            return state
    }
}

export default spinnerReducer;