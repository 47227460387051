import * as type from '../types/API/types'
import { IAction } from './userMetadataReducer'

const initialState = {
    data: null,
    loading: false,
    error: null,
}
const initialData ={
    topicData :{
        data: [],
        loading: false,
        error: null,
    }
}


export const getCoursesReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_COURSES:
            return {
                ...state,
                loading: true,
                // data : null
            }
        case type.GET_COURSES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_COURSES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const getStudentCoursesReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_STUDENT_COURSES:
            return {
                ...state,
                loading: true,
            }
        case type.GET_STUDENT_COURSES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_STUDENT_COURSES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const addCoursesReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.ADD_COURSE:
            return {
                ...state,
                loading: true,
                error: null
            }
        case type.ADD_COURSE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null
            }
        case type.ADD_COURSE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const editCoursesReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.EDIT_COURSE:
            return {
                ...state,
                loading: true,
            }
        case type.EDIT_COURSE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.EDIT_COURSE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const deleteCoursesReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.DELETE_COURSE:
            return {
                ...state,
                loading: true,
            }
        case type.DELETE_COURSE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null
            }
        case type.DELETE_COURSE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const addHeirarchyReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.ADD_HEIRARCHY:
            return {
                ...state,
                loading: true,
                data : {}
            }
        case type.ADD_HEIRARCHY_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.ADD_HEIRARCHY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const editHeirarchyReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.EDIT_HEIRARCHY:
            return {
                ...state,
                loading: true,
            }
        case type.EDIT_HEIRARCHY_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.EDIT_HEIRARCHY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const getCoursePagesReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_COURSE_PAGES:
            return {
                ...state,
                loading: true,
            }
        case type.GET_COURSE_PAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_COURSE_PAGES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const getCoursePagesNewReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_COURSE_PAGES_NEW:
            return {
                ...state,
                loading: true,
                data : []
            }
        case type.GET_COURSE_PAGES_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_COURSE_PAGES_NEW_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const getAiPages = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_AI_COURSE:
            return {
                ...state,
                loading: true,
                data : []
            }
        case type.GET_AI_COURSE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_AI_COURSE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const addTopic = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.ADD_TOPIC:
            return {
                ...state,
                loading: true,
                data : []
            }
        case type.ADD_TOPIC_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.ADD_TOPIC_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const getTopics = (state = initialData.topicData, action: IAction) => {
    switch(action.type) {
        case type.GET_TOPIC:
            return {
                ...state,
                loading: true,
            }
        case type.GET_TOPIC_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_TOPIC_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const getQuestionBank = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_QUESTION_BANK:
            return {
                ...state,
                loading: true,
            }
        case type.GET_QUESTION_BANK_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_QUESTION_BANK_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const getPagesReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_PAGES:
            return {
                ...state,
                loading: true,
            }
        case type.GET_PAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_PAGES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const addRegularPageReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.ADD_REGULAR_PAGE:
            return {
                ...state,
                loading: true,
            }
        case type.ADD_REGULAR_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.ADD_REGULAR_PAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const addPageReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.ADD_PAGE:
            return {
                ...state,
                loading: true,
            }
        case type.ADD_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.ADD_PAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const updatePageReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.UPDATE_PAGE:
            return {
                ...state,
                loading: true,
            }
        case type.UPDATE_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.UPDATE_PAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const updateRegularPageReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.UPDATE_REGULAR_PAGE:
            return {
                ...state,
                loading: true,
            }
        case type.UPDATE_REGULAR_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.UPDATE_REGULAR_PAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const convertPage = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.CONVERT_PAGE:
            return {
                ...state,
                loading: true,
            }
        case type.CONVERT_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.CONVERT_PAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const addQuestionPageReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.ADD_QUESTION_PAGE:
            return {
                ...state,
                loading: true,
            }
        case type.ADD_QUESTION_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.ADD_QUESTION_PAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const updateQuestionPageReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.UPDATE_QUESTION_PAGE:
            return {
                ...state,
                loading: true,
            }
        case type.UPDATE_QUESTION_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.UPDATE_QUESTION_PAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const deletePageReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.DELETE_PAGE:
            return {
                ...state,
                loading: true,
                data:{}
            }
        case type.DELETE_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null
            }
        case type.DELETE_PAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const deleteHeirarchyReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.DELETE_HEIRARCHY:
            return {
                ...state,
                loading: true,
                data:{}
            }
        case type.DELETE_HEIRARCHY_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null
            }
        case type.DELETE_HEIRARCHY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const submitQuizReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.SUBMIT_QUIZ:
            return {
                ...state,
                loading: true,
            }
        case type.SUBMIT_QUIZ_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null
            }
        case type.SUBMIT_QUIZ_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const viewSubmissionReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.VIEW_SUBMISSION:
            return {
                ...state,
                loading: true,
            }
        case type.VIEW_SUBMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null
            }
        case type.VIEW_SUBMISSION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}


export const getSubmission = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_SUBMISSION:
            return {
                ...state,
                loading: true,
            }
        case type.GET_SUBMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null
            }
        case type.GET_SUBMISSION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const getUserProfile = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_USER_INFO:
            return {
                ...state,
                loading: true,
            }
        case type.GET_USER_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null
            }
        case type.GET_USER_INFO_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const updateUserprofile = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.UPDATE_USER_INFO:
            return {
                ...state,
                loading: true,
                // data : null
            }
        case type.UPDATE_USER_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null
            }
        case type.UPDATE_USER_INFO_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
            
    }
}

export const getAnalyticReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_ANALYTIC:
            return {
                ...state,
                loading: true,
            }
        case type.GET_ANALYTIC_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_ANALYTIC_FAILED:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const GetCourseDetails = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_COURSE_DETAIL:
            return {
                ...state,
                loading: true,
            }
        case type.GET_COURSE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_COURSE_DETAIL_FAILED:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const EnableAnalytic = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.ENABLE_ANALYTIC:
            return {
                ...state,
                loading: true,
            }
        case type.ENABLE_ANALYTIC_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.ENABLE_ANALYTIC_FAILED:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const MovePage = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.MOVE_PAGE:
            return {
                ...state,
                loading: true,
                data : {}
            }
        case type.MOVE_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.MOVE_PAGE_FAILED:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const MoveFolder = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.MOVE_FOLDER:
            return {
                ...state,
                loading: true,
                data: {}
            }
        case type.MOVE_FOLDER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.MOVE_FOLDER_FAILED:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const ChangePasswordReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.CHANGE_PASSWORD:
            return {
                ...state,
                loading: true,
                data: {}
            }
        case type.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: action.payload.error
            }
        default:
            return state;
    }
}
export const getHelpReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_HELP:
            return {
                ...state,
                loading: true,
                data: {}
            }
        case type.GET_HELP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_HELP_FAILED:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: action.payload.error
            }
        default:
            return state;
    }
}
