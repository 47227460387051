import * as type from '../types/API/types'
import { IAction } from './userMetadataReducer'

const initialState = {
    data: null,
    loading: false,
    error: null,
    actionLoading : false
}

const loginReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.LOGIN_USER:
            return {
                ...state,
                loading: true,
                error: null,
                actionLoading : true
            }
        case type.LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null,
                actionLoading : false
            }
        case type.LOGIN_USER_FAILED:
            return {
                ...state,
                loading: false,
                actionLoading : false,
                error: action.payload.error
            }
        case type.RESET_USER_LOGIN:
            return {
                ...state,
                loading: false,
                actionLoading : false,
                error: null,
                data:null
            }
        default:
            return state;
    }
}

export const refreshTokenReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.REFRESH_TOKEN:
            return {
                ...state,
                loading: true,
                error: null
            }
        case type.REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null
            }
        case type.REFRESH_TOKEN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const registerUserReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.REGISTER_USER:
            return {
                ...state,
                loading: true,
                error: null,
                actionLoading:true
            }
        case type.REGISTER_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null,
                actionLoading:false
            }
        case type.REGISTER_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                actionLoading:false
            }
        default:
            return state;
    }
}

export const deleteUserReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.DELETE_USER:
            return {
                ...state,
                loading: true,
                error: null,
                actionLoading:true
            }
        case type.DELETE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null,
                actionLoading:false
            }
        case type.DELETE_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                actionLoading:false
            }
        default:
            return state;
    }
}
export const forgotPassword = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.FORGOT_PASSWORD:
            return {    
                ...state,
                loading: true,
                error: null,
                actionLoading:true
            }
        case type.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData,
                error: null,
                actionLoading:false
            }
        case type.FORGOT_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                actionLoading:false
            }
        default:
            return state;
    }
}

export default loginReducer;
