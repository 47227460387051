import React from 'react';
import Button from '../../components/Common/Button/Button';

interface SuccessProps {
    // Define your props here
    onClick:()=>void
}

const Success: React.FC<SuccessProps> = ({onClick}) => {
    return (
        <>
            <img src="https://firebasestorage.googleapis.com/v0/b/test-project-7bfa2.appspot.com/o/check.png?alt=media&token=fa66032f-8ae1-4fd5-aaf1-74a04367e336" alt="" />
            <div className='success_text_section'>
                <h1>Payment Successful</h1>
                <p>Thank you for your purchase!</p>
            </div>
            <div style={{ marginTop: "20px" }}>
                <Button btnText="Go to dashboard" onClick={onClick} />
            </div>
        </>
    );
};

export default Success;