import { Avatar, ChatContainer, Conversation, ConversationHeader, ConversationList, ExpansionPanel, MainContainer, Message, MessageInput, MessageList, Sidebar, } from '@chatscope/chat-ui-kit-react';
import { useEffect, useState } from 'react'
// import './App.css'
import 'firebase/database';
import firebase from "../../firebase"
import Chatbox from './chatbox';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { getDatabase, push, ref, update, set } from 'firebase/database';
import { getStudentsByClass } from '../../redux/actions/API/teacherCourses';
// import { ChatContainer, Conversation, ConversationHeader, ConversationList, MainContainer, Message, MessageInput, MessageList, Sidebar } from '@chatscope/chat-ui-kit-react';
import {
    Persona,
    PersonaSize,
    IPersonaSharedProps,
} from "@fluentui/react/lib/Persona";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
function Chat() {
    const studentList = useSelector((state: any) => state?.api?.getStudentsByClass?.data)
    const teacherList = useSelector((state: any) => state?.api?.getTeacherCourses?.data)
    const userMetadata: any = useSelector((state: any) => state.ui.userMetadata)
    const userProfileData = useSelector((state: any) => state?.api?.getUserProfile?.data)
    const [studentChatList, setStudentChatList] = useState<any[]>([])
    const [teacherChatList, setTeacherChatList] = useState<any[]>([])
    const [groupChatList, setGroupChatList] = useState<any[]>([])
    const [userChatList, setUserChatList] = useState<any[]>([])
    const [messages, setMessages] = useState<any[]>([])
    const [currentText, setCurrenttext] = useState("")
    const activeChat = studentList?.filter((item: any) => userMetadata?.id != item?.userId)
    const [currentUser, setCurrentUser] = useState(activeChat[0])
    const [loading, setLoading] = useState<boolean>(false)
    const [collapseClassList, setCollapseClasslist] = useState<any[]>([])
    const [unreadCount, setUnreadCount] = useState<any>({})
    const userRole = useSelector((state: any) => state?.ui?.userMetadata)
    const [messgaeIds, setMessageIds] = useState<any[]>([])
    const dispatch = useDispatch()
    const [showChats, setShowChats] = useState<boolean>(false)

    useEffect(() => {
        const times = setTimeout(() => {
            setShowChats(true)
        }, 1000);
        return () => {
            clearTimeout(times);
        };

    }, [])

    const database: any = firebase.database();

    useEffect(() => {
        let messagesRef: any
        const fetchData = async () => {
            if (currentUser?.type === "group") {
            } else {
                messagesRef = database.ref(`institute/${userRole.InstitutionName}/message`);
            }
            let messageArray: any
            await messagesRef.on('value', (snapshot: any) => {
                const data = snapshot.val();
                messageArray = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
                setMessageIds(messageArray)
            });
        }
        fetchData()
        return () => {
            messagesRef.off();
        };
    }, [])

    const showGroupData = (item: any) => {
        if (item?.type === "group") {
            if (item?.participants) {
                const matched = item.participants.findIndex((elem: any) => elem.userId === +userMetadata.id)
                return matched >= 0
            } else {
                return false
            }
        } else {
            return +userMetadata.id !== item.userId
        }
    }
    const showTeacherGroupData = (item: any) => {
        if (item?.type === "group") {
            return item.messages
        } else {
            let userIds: any
            if (+userMetadata.id < item.userId) {
                userIds = (+userMetadata.id) + '_' + item.userId
            } else {
                userIds = item.userId + '_' + (+userMetadata.id)
            }
            const returnData = messgaeIds.findIndex((item: any) => item.id === userIds)
            return returnData >= 0
        }
    }

    const returnKey = () => {
        if (currentUser?.type === "group") {
            return "classId"
        } else {
            return "userId"
        }
    }

    const iconClick = (e: any, elem: any) => {
        e.stopPropagation()
        setShowChats(false)
        dispatch(getStudentsByClass({ classId: elem.classId }))
    }
    const examplePersona: IPersonaSharedProps = {
        secondaryText: 'Designer',
        tertiaryText: 'In a meeting',
        optionalText: 'Available at 4:00pm',
    };


    useEffect(() => {
        if (studentList) {
            setStudentChatList(studentList)
        }
        if(teacherList){
            setTeacherChatList(teacherList)
        }
    }, [studentList , teacherList])

    useEffect(() => {
        setUserChatList([...studentChatList, ...teacherChatList , ...groupChatList])
    }, [studentList, groupChatList.length])


    const appendChannelId = (user1: number, user2: number) => {
        if (currentUser?.type === "group") {
            return currentUser?.id
        } else {
            if (user1 < user2) {
                return user1 + '_' + user2
            } else {
                return user2 + '_' + user1
            }
        }
    }


    useEffect(() => {
        let messagesRef: any
        if (currentUser?.type === "group") {
            messagesRef = database.ref(`institute/${userMetadata.InstitutionName}/groups/${currentUser?.id}/messages`)
        } else {
            messagesRef = database.ref(`institute/${userMetadata.InstitutionName}/message/${appendChannelId(+userMetadata.id, +currentUser?.userId)}`);

        }
        messagesRef.on('value', (snapshot: any) => {
            const data = snapshot.val();
            const messagesArray = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
            setMessages(messagesArray);
            const getDatabaseFunc = getDatabase();
            if (unreadCount[currentUser?.userId]) {
                const groupRef = ref(getDatabaseFunc, `institute/${userMetadata.InstitutionName}/unseenMessage/${+userMetadata.id}/${currentUser?.userId}/${unreadCount[currentUser?.userId].id}`);
                update(groupRef, { count: 0 })
            }
        });
        return () => {
            messagesRef.off();
        };
    }, [currentUser?.userId]);
    useEffect(() => {
        const messagesRef = database.ref(`institute/${userMetadata.InstitutionName}/users`)
        messagesRef.once('value', (snapshot: any) => {
            const data = snapshot.val();
            const messagesArray = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
            const filterGroup = messagesArray.filter((item: any) => {
                return item.classId === 22
            })
            if (userMetadata.role === "Student") {
                // setGroupChatList(filterGroup)
            } else {
                setCollapseClasslist(messagesArray)
                // setGroupChatList(messagesArray)
            }
        });
        return () => {
            messagesRef.off();
        };
    }, [currentUser?.userId]);

    useEffect(() => {
        let messagesRef: any
        const fetchData = async () => {
            try {
                setLoading(true)
                messagesRef = database.ref(`institute/${userMetadata.InstitutionName}/groups`);
                const snapshot = await messagesRef.once('value');
                const data = snapshot.val();
                const messagesArray = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        return () => {
            if (messagesRef) {
                messagesRef.off();
            }
        };
    }, [currentUser?.userId]);


    useEffect(() => {
        let messagesRef: any
        messagesRef = database.ref(`institute/${userMetadata.InstitutionName}/groups`)
        messagesRef.on('value', (snapshot: any) => {
            const data = snapshot.val();
            const messagesArray = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
            setGroupChatList(messagesArray);
        });
        return () => {
            messagesRef.off();
        };
    }, [currentUser?.userId]);

    console.log("userChatListuserChatList" ,userChatList , "currentUser" , currentUser , userMetadata)

    const transformData = (data: any): any => {
        const result: any = {};

        for (const channelId in data) {
            result[channelId] = {};

            for (const messageId in data[channelId]) {
                result[channelId] = {
                    id: messageId,
                    count: data[channelId][messageId].count,
                    lastMessage: data[channelId][messageId].lastMessage
                };
            }
        }

        return result;
    };

    const sendText = async () => {
        let newMessage: any
        if (currentUser?.type === "group") {
            newMessage = firebase.database().ref(`institute/${userMetadata.InstitutionName}/groups/${currentUser?.id}/messages`).push()
        } else {
            newMessage = firebase.database().ref(`institute/${userMetadata.InstitutionName}/message/${appendChannelId(+(userMetadata.id), +currentUser?.userId)}`).push();
        }
        const chat = {
            sentAt: new Date().getTime(),
            isSeen: false,
            userId: +userMetadata.id,
            message: currentText,
            name: userProfileData?.firstName,
            picture: userProfileData?.picture,
        }
        newMessage.set(chat);
        setCurrenttext("")
        const getDatabaseFunc = getDatabase();
        const unseenMessageRef = database.ref(`institute/${userMetadata.InstitutionName}/unseenMessage/${currentUser?.userId}`)
        let unseenData: any
        await unseenMessageRef.on('value', (snapshot: any) => {
            const data = snapshot.val();
            unseenData = transformData(data)
        });
        if (unseenData) {
            if (unseenData[userMetadata.id]) {
                const groupRef = ref(getDatabaseFunc, `institute/${userMetadata.InstitutionName}/unseenMessage/${currentUser?.userId}/${userMetadata.id}/${unseenData[userMetadata.id].id}`);
                update(groupRef, { count: unseenData[userMetadata.id].count + 1, lastMessage: currentText })
            } else {
                const newGroupRef = push(ref(getDatabaseFunc, `institute/${userMetadata.InstitutionName}/unseenMessage/${currentUser?.userId}/${userMetadata.id}`));
                set(newGroupRef, {
                    count: 1,
                    lastMessage: currentText
                });
            }
        } else {
            const newGroupRef = push(ref(getDatabaseFunc, `institute/${userMetadata.InstitutionName}/unseenMessage/${currentUser?.userId}/${userMetadata.id}`));
            set(newGroupRef, {
                count: 1,
                lastMessage: currentText
            });
        }

    }
    const textChange = (e: any) => {
        setCurrenttext(e)
    }
    const chatClick = async (item: any) => {
        setCurrentUser(item)
        const unseenMessageRef = database.ref(`institute/${userMetadata.InstitutionName}/unseenMessage/${userMetadata.id}`)
        const getDatabaseFunc = getDatabase();
        let unseenData: any ={}
        if (unreadCount[item?.userId]) {
            const groupRef = ref(getDatabaseFunc, `institute/${userMetadata.InstitutionName}/unseenMessage/${userMetadata.id}/${item?.userId}/${unseenData[item?.userId]?.id}`);
            update(groupRef, { count: 0 })
        }
    }

    console.log("userMetadata" , userMetadata);
    


    useEffect(() => {
        const fetchData = async () => {
            const unseenMessageRef = database.ref(`institute/${userMetadata.InstitutionName}/unseenMessage/${userMetadata.id}`)
            let unseenData: any
            unseenMessageRef.on('value', (snapshot: any) => {
                const data = snapshot.val();
                unseenData = transformData(data)
                setUnreadCount(unseenData)
            });
        }
        fetchData()
    }, [])

    console.log("unreadCountunreadCount" , unreadCount)

    return (
        <div className="homeworkElementsContainer">
            <MainContainer
                responsive
                style={{
                    height: "calc(100vh - 120px)"
                }}>
                <Sidebar position="left" >
                    <div className="normal_chat_list">
                        <ConversationList>
                            {userChatList.map((item: any) => {
                                return (
                                    (userRole.role === "Student" ? showGroupData(item) : showTeacherGroupData(item)) ?
                                        <Conversation
                                            key={item.firstName ? `${item.firstName} ${item.middleName} ${item.lastName}` : item?.name}
                                            active={currentUser ? +currentUser[returnKey()] === item[returnKey()] : false}
                                            onClick={() => chatClick(item)}
                                            name={item.firstName ? `${item.firstName} ${item.middleName} ${item.lastName}` : item?.name}
                                            unreadCnt={unreadCount[item.userId]?.count ? unreadCount[item.userId]?.count : 0}
                                            info={unreadCount[item.userId]?.lastMessage ? unreadCount[item.userId]?.lastMessage : ""}
                                        >
                                            <Avatar><Persona {...examplePersona} text={item.firstName ? `${item.firstName} ${item.middleName} ${item.lastName}` : item?.name} size={PersonaSize.size32} /></Avatar>

                                        </Conversation> : ""
                                )
                            })}

                        </ConversationList>
                    </div>
                    {userRole.role !== "Student" ?
                        <div className="group_list">
                            {groupChatList.map((elem: any) => {
                                return (
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            className='groupChatList'
                                        >
                                            <Typography>{elem.firstName ? `${elem.firstName} ${elem.middleName} ${elem.lastName}` : elem?.name}</Typography>
                                            <RefreshIcon onClick={(e: any) => iconClick(e, elem)} />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ConversationList>
                                                <Conversation
                                                    active={currentUser ? +currentUser[returnKey()] === elem[returnKey()] : false}
                                                    onClick={() => chatClick(elem)}
                                                    name={elem.firstName ? `${elem.firstName} ${elem.middleName} ${elem.lastName}` : elem?.name}
                                                >
                                                    <Avatar><Persona {...examplePersona} text={elem.firstName ? `${elem.firstName} ${elem.middleName} ${elem.lastName}` : elem?.name} size={PersonaSize.size32} /></Avatar>
                                                </Conversation>
                                                {elem?.participants?.map((item: any) => {
                                                    return (
                                                        showGroupData(item) ?
                                                            (item?.role !== "Teacher" ?
                                                                <Conversation
                                                                    active={currentUser ? +currentUser[returnKey()] === item[returnKey()] : false}
                                                                    onClick={() => chatClick(item)}
                                                                    name={item.firstName ? `${item.firstName} ${item.middleName} ${item.lastName}` : item?.name}
                                                                >
                                                                    <Avatar><Persona {...examplePersona} text={item.firstName ? `${item.firstName} ${item.middleName} ${item.lastName}` : item?.name} size={PersonaSize.size32} /></Avatar>
                                                                </Conversation> : "")
                                                            : ""
                                                    )
                                                })}

                                            </ConversationList>
                                        </AccordionDetails>
                                    </Accordion>

                                )
                            })}
                        </div> : ""}

                </Sidebar>
                <ChatContainer>
                    <ConversationHeader>
                        <ConversationHeader.Back />
                        <Avatar><Persona {...examplePersona} text={currentUser?.firstName ? `${currentUser?.firstName} ${currentUser?.middleName} ${currentUser?.lastName}` : currentUser?.name} size={PersonaSize.size32} /></Avatar>
                        <ConversationHeader.Content
                            userName={currentUser?.firstName ? `${currentUser?.firstName} ${currentUser?.middleName} ${currentUser?.lastName}` : currentUser?.name}
                        />
                    </ConversationHeader>

                    <MessageList >
                        {messages.map((item: any) => {
                            return (
                                <Message
                                    model={{
                                        direction: +userMetadata.id === item.userId ? "outgoing" : 'incoming',
                                        message: item.message,
                                        position: 'single',
                                        sender: 'Zoe',
                                        sentTime: '15 mins ago'
                                    }}
                                >
                                    <Message.Footer
                                        sender={item.name}
                                    // sentTime="just now"
                                    />

                                    <Avatar><Persona {...examplePersona} text={item.name} size={PersonaSize.size32} /></Avatar>
                                </Message>
                            )
                        })}
                    </MessageList>
                    {showChats ?
                        <MessageInput id='messages_inp' attachButton={false} onSend={sendText} value={currentText} onChange={(e: any) => textChange(e)} placeholder="Type message here" />
                        : ""}
                </ChatContainer>
            </MainContainer>
        </div>
    )
}

export default Chat



